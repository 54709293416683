import { MoneyModel } from "../MoneyModel";
import { InvoiceDigestStatus } from "../../../../scout/types";
export class InvoicesDigestModel {
  static fromDTO(dto) {
    return new InvoicesDigestModel({
      statuses: {
        paymentsVerificationRequired: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.paymentsVerificationRequired),
        requiresClientApproval: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.requiresClientApproval),
        paymentStopped: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.paymentStopped),
        disputed: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.disputed),
        failed: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.failed),
        missingPaymentMethod: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.missingPaymentMethod)
      },
      processing: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.processing),
      requiresAttention: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.requiresAttention),
      hasCreatedInvoice: dto.hasCreatedInvoice
    });
  }
  static invoiceStatusGroupFromDTO(dto) {
    return {
      count: dto.count,
      amount: MoneyModel.fromDTO(dto.amount)
    };
  }
  constructor(data) {
    this.data = data;
  }
  percentages() {
    const totalAmount = this.totalAmount();
    if (totalAmount === 0) {
      return [];
    }
    return this.invoiceTypesToDisplay().map(type => {
      const {
        count,
        amount
      } = this.data.statuses[type];
      return {
        type,
        count,
        amount,
        percentage: amount.amount().div(totalAmount).mul(100).toNumber()
      };
    });
  }
  numInvoiceTypes() {
    return this.invoiceTypesToDisplay().length;
  }
  invoiceTypesToDisplay() {
    const statusesOrder = {
      [InvoiceDigestStatus.RequiresClientApproval]: this.data.statuses.requiresClientApproval.amount.toNumber(),
      [InvoiceDigestStatus.PaymentsVerificationRequired]: this.data.statuses.paymentsVerificationRequired.amount.toNumber(),
      [InvoiceDigestStatus.MissingPaymentMethod]: this.data.statuses.missingPaymentMethod.amount.toNumber(),
      [InvoiceDigestStatus.Failed]: this.data.statuses.failed.amount.toNumber(),
      [InvoiceDigestStatus.Disputed]: this.data.statuses.disputed.amount.toNumber(),
      [InvoiceDigestStatus.PaymentStopped]: this.data.statuses.paymentStopped.amount.toNumber()
    };
    return Object.entries(statusesOrder).sort((a, b) => b[1] - a[1]).map(_ref => {
      let [status] = _ref;
      return status;
    }).filter(status => this.data.statuses[status].count > 0);
  }
  invoiceTypeToInvoicesGroup(invoiceType) {
    return this.data.statuses[invoiceType];
  }
  hasCreatedInvoice() {
    return this.data.hasCreatedInvoice;
  }
  processing() {
    return this.data.processing;
  }
  requiresAttention() {
    return this.data.requiresAttention;
  }
  totalAmount() {
    return this.data.requiresAttention.amount.toNumber();
  }
}