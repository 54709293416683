import { getTitleValidator } from 'validators';
import * as selectors from "./selectors";
import { serviceModel } from '..';
import compact from 'lodash/compact';
import { isBefore } from 'date-fns';
export function validate(service) {
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return [...validateMaxCharges(service), ...validateDayOfMonth(service), ...validatePriceIncrease(service, opts), ...validateIntegratedClass(service)];
}
export function validateDayOfMonth(service) {
  const billingDayOfMonth = serviceModel.billingDayOfMonth(service);
  const isBilledUpfront = serviceModel.isBilledUpfront(service);
  if (!billingDayOfMonth || isBilledUpfront == null) return [];
  return validateBillingDayOfMonth(billingDayOfMonth, isBilledUpfront) ? ['billed upfront on last day of month'] : [];
}
export function validateMaxCharges(service) {
  const maxCharges = selectors.maxChargesLimit(service);
  const isMaxChargesEnabled = selectors.maxChargesEnabled(service);
  if (isMaxChargesEnabled) {
    if (maxCharges == null) {
      return ['max charges is empty'];
    } else if (maxCharges < 1 || maxCharges > 99) {
      return ['max charges not in valid range (1 >= maxCharges <= 99)'];
    }
  }
  return [];
}
export const roundPricesErrorMsg = 'Round down may reduce the rate below the original.';
export const increaseMethodErrorMsg = 'anniversary option is not allowed';
export function validatePriceIncrease(service) {
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const priceIncrease = serviceModel.priceIncrease(service);
  const increaseOn = priceIncrease.increaseOn();
  return compact([priceIncrease.exists() && isInvalidPercentIncrease(priceIncrease.percentIncrease()) && 'amount must be positive', isInvalidRoundPrices(service) && roundPricesErrorMsg, priceIncrease.exists() && isInvalidIncreaseMethod(increaseOn, opts.relationshipEffectiveDate) && increaseMethodErrorMsg, priceIncrease.exists() && increaseOn && opts.relationshipEffectiveDate && isBefore(increaseOn, opts.relationshipEffectiveDate) && 'date must be after agreement effective date']);
}
export function isInvalidIncreaseMethod(increaseOn, relationshipEffectiveDate) {
  return !increaseOn && !!relationshipEffectiveDate;
}
export function isInvalidPercentIncrease(percentIncrease) {
  if (typeof percentIncrease === 'string') {
    return !percentIncrease || +percentIncrease <= 0;
  }
  return percentIncrease.isNegative() || percentIncrease.isZero();
}
export function isInvalidRoundPrices(service) {
  const nextYearPrice = selectors.nextYearPrice(service);
  return nextYearPrice != null && selectors.finalPrice(service, 1).gt(nextYearPrice);
}
export function validateName(name, nameBlacklist) {
  const trimmed = name.trim();
  if (!trimmed) {
    return 'Service name is required';
  }
  if (nameBlacklist && nameBlacklist.includes(trimmed)) {
    return 'You already use this service name';
  }
  const validation = getTitleValidator('Service name')(name);
  if (validation !== true) {
    return typeof validation === 'string' ? validation : 'Service name is invalid';
  }
  return undefined;
}
export function isInvalid(service) {
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return validate(service, opts).length > 0;
}

/** Will return true if there's an error. */
export const validateBillingDayOfMonth = (billingDayOfMonth, isBilledUpfront) => {
  return billingDayOfMonth.isLast() && isBilledUpfront;
};
export function validateIntegratedClass(service) {
  const integratedClassId = serviceModel.integratedClassId(service);
  if (integratedClassId === '') {
    return ['Please select a class'];
  }
  return [];
}