import { serviceBillingModel, serviceModel } from "../../../models";
import { BillingTriggerType } from "../../../types/api.model";
export function getBillingBadgeText(service) {
  const billing = serviceModel.billingTrigger(service);
  const trigger = serviceBillingModel.trigger(billing);
  const maxCharges = serviceModel.maxChargesEnabled(service) ? serviceModel.maxChargesLimit(service) : null;
  const recurrencePeriod = serviceBillingModel.isRecurring(billing) ? serviceModel.recurrencePeriod(billing) : null;
  if (recurrencePeriod) {
    return {
      text: "Billed ".concat(recurrencePeriod).concat(maxCharges != null ? ", ".concat(maxCharges, " times") : ''),
      intent: 'recurring'
    };
  } else if (trigger === BillingTriggerType.OneTimeManual) {
    return {
      text: 'Billed once',
      intent: 'onetime-manual'
    };
  } else if (trigger === BillingTriggerType.OnApproval) {
    return {
      text: 'Billed once, on agreement approval',
      intent: 'approval'
    };
  } else {
    // oneTimeDelivery service
    return {
      text: 'Billed once, on approval of delivery',
      intent: 'delivery'
    };
  }
}