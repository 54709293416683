// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YzIUI{height:65px;width:100%;background-color:#fff;border-bottom:solid 1px #eaeaef;display:flex;justify-content:space-between;align-items:stretch}.OUShz{height:32px;width:100%;background-color:#6942f8;text-align:center;display:flex;justify-content:center;align-items:center;gap:16px;color:#fff}.CWUvh{color:#fff !important}.m962J{display:flex;flex:1;width:200px;align-items:center;gap:16px}.Yc7fT{width:64px;height:64px;border:solid 1px #eaeaef;border-top:none;border-bottom:none;border-radius:0px}.YL1kd{display:flex;justify-content:space-between}.xpKUk{display:flex;align-items:center;cursor:pointer;height:100%;margin:0px 16px;text-align:center;position:relative}.xpKUk .Ppl8m{position:absolute;inset-inline-end:0;inset-block-start:0;transform:translate(100%, 100%)}.j82Rx{pointer-events:none;opacity:.5}.L_Pgz{display:flex;flex:1;width:200px;justify-content:flex-end;padding-right:32px;align-items:center}.zLnWh{position:relative}.zLnWh .Ppl8m{position:absolute;inset-inline-end:0;inset-block-start:0;transform:translate(100%, -25%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"topBar": `YzIUI`,
	"banner": `OUShz`,
	"bannerText": `CWUvh`,
	"btnBackWrapper": `m962J`,
	"btnBack": `Yc7fT`,
	"tabsContainer": `YL1kd`,
	"tab": `xpKUk`,
	"pimple": `Ppl8m`,
	"tabDisabled": `j82Rx`,
	"name": `L_Pgz`,
	"tabV2": `zLnWh`
};
export default ___CSS_LOADER_EXPORT___;
