// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oEJHe{display:flex;flex-direction:column;margin-bottom:16px}.GEjmT{border-radius:50%;height:120px;max-width:120px;min-width:120px;text-align:center;background-color:#fff;position:relative}.KUcmf{z-index:15;border:solid 2px #eaeaef;position:absolute;border-radius:50%;top:0;left:0;right:0;bottom:0;pointer-events:none}.u2kYP{overflow:hidden;min-height:84px;max-height:84px;min-width:84px;max-width:84px;position:absolute;top:0;left:0;display:flex;justify-content:center;align-items:center;background-size:contain;background-position:center;background-repeat:no-repeat;margin:18px}.ebu4z{border-radius:50%;height:120px;max-width:120px;min-width:120px;display:flex;justify-content:center;align-items:center;cursor:pointer;position:relative;padding:8px}.KPNdc{border-radius:50%;height:120px;max-width:120px;min-width:120px;opacity:0;overflow:hidden;position:absolute;cursor:pointer}.MuDML{max-width:700px;display:flex;flex-direction:column;gap:16px;margin-bottom:24px;align-items:flex-start}.aJcGc{display:flex;align-items:center;gap:24px;justify-content:space-between}.MDYYT{display:flex;flex-direction:column;flex-wrap:wrap;align-items:flex-end}.zXqAT{display:flex;justify-content:space-between}.w3mPY{display:flex;justify-content:center;align-items:center;height:100%;width:100%}.F2myo{display:flex;width:100%;gap:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"brandElementsContainer": `oEJHe`,
	"circle": `GEjmT`,
	"logoImageBorder": `KUcmf`,
	"logoImageContainer": `u2kYP`,
	"logoDragAndDropLabel": `ebu4z`,
	"inputFile": `KPNdc`,
	"fieldsLayout": `MuDML`,
	"address": `aJcGc`,
	"bankActions": `MDYYT`,
	"bankDetails": `zXqAT`,
	"taxInfoLoader": `w3mPY`,
	"taxIdContainer": `F2myo`
};
export default ___CSS_LOADER_EXPORT___;
