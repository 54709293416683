import Decimal from 'decimal.js';
import { DiscountModel } from '..';
import { makeFixedCost, makeRangeCost, makeVariableCost } from "./makers";
import { ServiceCostType } from "./model";
import { utils } from "../../utils";
import { PriceIncreaseModel, PriceIncreaseType } from "../PriceIncreaseModel";
export function fromDTO(cost) {
  const discount = DiscountModel.fromDTO(cost.discount);
  const priceIncrease = PriceIncreaseModel.fromDTO(cost.priceIncrease);
  switch (cost.pricingType) {
    case ServiceCostType.Fixed:
      return makeFixedCost(cost.price, {
        discount,
        priceIncrease
      });
    case ServiceCostType.Variable:
      return makeVariableCost(cost.price, {
        discount,
        unitCap: cost.unitCapStr != null ? new Decimal(cost.unitCapStr.replace(/,/g, '')) : null,
        unitName: cost.unitName,
        priceIncrease
      });
    case ServiceCostType.Range:
      return makeRangeCost(cost.maxPrice, {
        discount,
        minPrice: cost.minPrice,
        priceIncrease
      });
  }
}
export function toDTO(cost) {
  const discount = cost.discount && cost.discount.toDTO();
  const priceIncrease = cost.priceIncrease.toDTO();
  return cost.pricingType === ServiceCostType.Fixed ? {
    pricingType: cost.pricingType,
    price: cost.price.toNumber(),
    discount,
    priceIncrease
  } : cost.pricingType === ServiceCostType.Range ? {
    pricingType: cost.pricingType,
    minPrice: cost.minPrice ? cost.minPrice.toNumber() : null,
    maxPrice: cost.maxPrice.toNumber(),
    discount,
    priceIncrease
  } : {
    pricingType: cost.pricingType,
    unitName: cost.unitName,
    price: cost.price.toNumber(),
    unitCapStr: cost.unitCap ? cost.unitCap.toDP(2).toString() : null,
    discount,
    priceIncrease
  };
}
export function fromDBDTO(cost) {
  const discount = cost.discount && DiscountModel.fromDBDTO(cost.discount);
  if (cost.pricingType.type === ServiceCostType.Fixed) {
    return makeFixedCost(utils.centsToDollars(cost.pricingType.fixed.price), {
      discount
    });
  } else if (cost.pricingType.type === ServiceCostType.Variable) {
    return makeVariableCost(utils.centsToDollars(cost.pricingType.variable.unitPrice), {
      discount,
      unitCap: cost.pricingType.variable.cap != null ? utils.centsToDollars(cost.pricingType.variable.cap) : null,
      unitName: cost.pricingType.variable.name
    });
  } else {
    return makeRangeCost(utils.centsToDollars(cost.pricingType.range.maxPrice), {
      discount,
      minPrice: cost.pricingType.range.minPrice != null ? utils.centsToDollars(cost.pricingType.range.minPrice) : null
    });
  }
}
export function toDBDTO(cost) {
  const discount = cost.discount && cost.discount.toDBDTO();
  const priceIncrease = {
    type: PriceIncreaseType.None
  };
  switch (cost.pricingType) {
    case ServiceCostType.Fixed:
      return {
        discount,
        priceIncrease,
        pricingType: {
          type: ServiceCostType.Fixed,
          fixed: {
            price: utils.dollarsToCents(cost.price)
          },
          variable: null,
          range: null
        }
      };
    case ServiceCostType.Variable:
      return {
        discount,
        priceIncrease,
        pricingType: {
          type: ServiceCostType.Variable,
          fixed: null,
          variable: {
            unitPrice: utils.dollarsToCents(cost.price),
            cap: cost.unitCap != null ? utils.dollarsToCents(cost.unitCap) : null,
            name: cost.unitName
          },
          range: null
        }
      };
    case ServiceCostType.Range:
      return {
        discount,
        priceIncrease,
        pricingType: {
          type: ServiceCostType.Range,
          fixed: null,
          variable: null,
          range: {
            minPrice: cost.minPrice != null ? utils.dollarsToCents(cost.minPrice) : null,
            maxPrice: utils.dollarsToCents(cost.maxPrice)
          }
        }
      };
  }
}