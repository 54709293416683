function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { notEmpty } from "../../infra/utils";
import * as serviceModel from "../service";
import * as legalTermsModel from "../legal-terms";
function fromDTO(draft) {
  var _draft$serviceBundles;
  return Object.assign({}, draft, {
    services: draft.services.map(serviceModel.fromDTO).filter(notEmpty)
  }, draft.serviceBundles ? {
    serviceBundles: {
      bundles: (_draft$serviceBundles = draft.serviceBundles) === null || _draft$serviceBundles === void 0 ? void 0 : _draft$serviceBundles.bundles.map(bundle => Object.assign({}, bundle, {
        services: bundle.services.map(serviceModel.fromDTO).filter(notEmpty)
      }))
    }
  } : {});
}
function toDTO(draft) {
  return _objectSpread(_objectSpread({}, draft), {}, {
    services: draft.services.map(serviceModel.toDTO),
    serviceBundles: draft.serviceBundles != null ? {
      bundles: draft.serviceBundles.bundles.map(bundle => _objectSpread(_objectSpread({}, bundle), {}, {
        services: bundle.services.map(serviceModel.toDTO)
      }))
    } : undefined
  });
}
function fromRelationship(relationship, legalTermsDocIds) {
  return {
    id: relationship.id,
    coverLetter: relationship.customNotificationText,
    extraClause: relationship.extraClause || '',
    requirePaymentMethod: relationship.requirePaymentMethod != null ? relationship.requirePaymentMethod : true,
    allowManualPayments: relationship.allowManualPayments,
    isExistingCustomer: false,
    customNotificationText: relationship.customNotificationText,
    title: relationship.title,
    lastModifiedOn: 0,
    lastModifiedBy: null,
    services: relationship.services,
    serviceBundles: relationship.serviceBundles,
    netTerms: relationship.netTerms,
    effectiveDate: relationship.effectiveDate,
    feeControls: relationship.feeControls,
    creationStrategy: null,
    clientContactId: relationship.clientContactId,
    isSample: relationship.isSample,
    amendmentsApprovalMethod: relationship.amendmentsApprovalMethod,
    invoicesApprovalMethod: relationship.invoicesApprovalMethod,
    requestAccountantAccess: relationship.requestAccountantAccess,
    introVideo: relationship.introVideo,
    legalTerms: legalTermsModel.toDraftLegalTerms(relationship.legalTerms, !legalTermsDocIds.includes(relationship.legalTerms.id)),
    reviewers: [...(relationship.signatories || []).map(s => ({
      firstName: s.firstName || undefined,
      lastName: s.lastName || undefined,
      email: s.email,
      isSignatory: true
    })), ...relationship.reviewers.map(r => ({
      firstName: r.firstName || undefined,
      lastName: r.lastName || undefined,
      email: r.email,
      isSignatory: false
    }))]
  };
}
export const translator = {
  fromDTO,
  toDTO,
  fromRelationship
};