// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y5zo8{margin-left:8px}.DohPU{list-style:disc;margin-inline-start:16px}.pjnw5{margin-top:8px}.AEexc{margin-bottom:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changes": `Y5zo8`,
	"changeItem": `DohPU`,
	"approvalInfo": `pjnw5`,
	"amendmentDate": `AEexc`
};
export default ___CSS_LOADER_EXPORT___;
