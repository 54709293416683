import { utils } from "../../utils";

/** Turns an integer of cents to a floating-point number of dollars. */
export const fromCents = value => {
  if (value === '' || value === 0) {
    return value;
  }
  return utils.centsToDollars(+value);
};

/** Turns a floating-point number of dollars to an integer of cents. */
export const toCents = value => {
  if (value === '' || value === 0) {
    return value;
  }
  return utils.dollarsToCents(+value);
};