import { paymentMethodModel } from "../../models";
import { UserRole } from "../../models/UserInfoModel";
export function isSelectablePaymentMethod(paymentMethod, isTerminatedAgreement, isSelected, isProposal, selectedPMRequiresVerification) {
  return !isTerminatedAgreement && !isSelected && !(paymentMethodModel.isBankAccount(paymentMethod) && paymentMethodModel.isErroredBankAccount(paymentMethod.bankAccount)) && !paymentMethodModel.isFailedDueToDuplicate(paymentMethod) && (isProposal || selectedPMRequiresVerification ? paymentMethodModel.requiresVerification(paymentMethod) || paymentMethodModel.isVerified(paymentMethod) : paymentMethodModel.isVerified(paymentMethod));
}
export async function fetchPaymentMethods(_ref) {
  let {
    isPreview,
    isSample,
    isProposal,
    vendorId,
    businessId,
    initialSelectedPaymentMethodId,
    getSelectedPaymentMethod,
    vendorGetPaymentMethod,
    clientGetPaymentMethod
  } = _ref;
  if (isPreview || isSample) {
    return {
      paymentMethods: []
    };
  }
  const isVendorInRelationship = businessId === vendorId;
  const fetchSelectedFundingSourceId = isProposal ? Promise.resolve(initialSelectedPaymentMethodId) : getSelectedPaymentMethod().then(selected => selected === null || selected === void 0 ? void 0 : selected.id);
  const [paymentMethods, selectedFundingSourceId] = await Promise.all([isVendorInRelationship ? vendorGetPaymentMethod() : clientGetPaymentMethod(), fetchSelectedFundingSourceId]);
  return {
    paymentMethods,
    selectedFundingSourceId
  };
}
export function createPaymentSetupHandlers(_ref2) {
  let {
    isVendor,
    paymentMethodHandlers,
    customerId,
    vendorId
  } = _ref2;
  const createSetupIntent = () => isVendor ? paymentMethodHandlers.vendorCreateSetupIntent(customerId) : paymentMethodHandlers.clientCreateSetupIntent(vendorId);
  const addToBusiness = paymentMethodId => isVendor ? paymentMethodHandlers.vendorAddToBusiness(paymentMethodId, customerId) : paymentMethodHandlers.clientAddToBusiness(paymentMethodId, vendorId);
  return {
    createSetupIntent,
    addToBusiness
  };
}
export function getRequiresPMActionConfirmation(isVendor, userRole, isProposal) {
  return (isVendor || userRole === UserRole.Accountant) && !isProposal;
}
export function doPaymentMethodAction(action, requiresPMActionConfirmation, openOnBehalfModal, paymentMethod) {
  if (requiresPMActionConfirmation) {
    openOnBehalfModal(action, paymentMethod);
  } else {
    action();
  }
}
export function getRenderedMenuItem(_ref3) {
  let {
    disconnectable,
    onDisconnect,
    removable,
    onRemove
  } = _ref3;
  if (disconnectable) {
    return {
      type: 'disconnect',
      onClick: onDisconnect
    };
  } else if (removable) {
    return {
      type: 'remove',
      onClick: onRemove
    };
  } else {
    return {
      type: 'disabled-remove'
    };
  }
}