import { useMemo, useState } from 'react';
import { useEventReporter, useGlobalContext, useStoreActions } from "../contexts";
export function useResendInvoice() {
  const {
    api,
    logBI
  } = useStoreActions();
  const {
    showErrorToast,
    showSuccessToast
  } = useGlobalContext();
  const {
    reportToHeap
  } = useEventReporter();
  const [loading, setLoading] = useState(false);
  return useMemo(() => {
    return {
      isResendingInvoice: loading,
      async resendInvoice(invoice) {
        logBI(10581);
        reportToHeap('Invoice', 'click', 'resend');
        try {
          setLoading(true);
          await api.invoice.resend(invoice.id);
          showSuccessToast("Email sent ".concat('relationship' in invoice ? "to ".concat(invoice.relationship.customer.name) : 'successfully'));
        } catch (error) {
          showErrorToast('Failed to send email');
          throw error;
        } finally {
          setLoading(false);
        }
      }
    };
  }, [loading]);
}