import { useStoreActions } from "../contexts";
export function useCreateRelationshipFromDraft() {
  const {
    api,
    markHasSentAProposal
  } = useStoreActions();
  return async (draftId, opts) => {
    const relationshipId = await api.relationship.createFromDraft(draftId, opts);
    markHasSentAProposal();
    return relationshipId;
  };
}