function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import * as selectors from "./selectors";
import { makeMaxCharges } from "./makers";
export function setBillingDayOfWeek(billing, billingDayOfWeek) {
  return selectors.isRecurring(billing) && selectors.periodRequiresBillingDayOfWeek(billing.recurrencePeriod) ? _objectSpread(_objectSpread({}, billing), {}, {
    billingDayOfWeek
  }) : billing;
}
export function setBillingDayOfMonth(billing, billingDayOfMonth) {
  return selectors.isMonthly(billing) && selectors.isRecurring(billing) ? _objectSpread(_objectSpread({}, billing), {}, {
    billingDayOfMonth
  }) : billing;
}
export function setMaxCharges(billing, enabled, limit) {
  if (selectors.isRecurring(billing)) {
    return _objectSpread(_objectSpread({}, billing), {}, {
      maxCharges: {
        enabled,
        limit
      }
    });
  } else {
    return billing;
  }
}
export function deleteMaxCharges(billing) {
  if (selectors.isRecurring(billing)) {
    return _objectSpread(_objectSpread({}, billing), {}, {
      maxCharges: makeMaxCharges(false)
    });
  }
  return billing;
}