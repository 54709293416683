import { useIoContext, useStoreActions, useStoreStatic } from "../contexts";
import { useGlobalContext } from 'seagull';
import { selectors } from "../store/state-selectors";
import { KycCompletionStatus } from "../store/store.model";
import { InquiryStatusEnum, KYBProgressStatusEnum } from 'anchor-kyb-service/types';
import { handlePromise } from "../infra/utils";
const personaInquiryLoader = 'persona-inquiry';
export function useStartKYC() {
  const {
    personaClient,
    openNewTab
  } = useIoContext();
  const {
    api,
    fetchPersonaKYBStatus
  } = useStoreActions();
  const {
    getState
  } = useStoreStatic();
  const {
    startLoader,
    stopLoader,
    showErrorToast
  } = useGlobalContext();
  async function startKYCPersona(businessId) {
    startLoader(personaInquiryLoader);
    const result = await handlePromise(api.vendor.getKYBInquiry(businessId));
    if (result.success) {
      const {
        inquiryId,
        sessionToken,
        inquiryStatus
      } = result.data;
      if (InquiryStatusEnum.NeedsReview === inquiryStatus) {
        await fetchPersonaKYBStatus();
        stopLoader(personaInquiryLoader);
        return;
      }
      const client = personaClient({
        onError: () => stopLoader(personaInquiryLoader),
        onReady: () => stopLoader(personaInquiryLoader),
        onComplete: () => fetchPersonaKYBStatus()
      });
      client.open(inquiryId, sessionToken);
    } else {
      showErrorToast('Failed to start KYB process');
      stopLoader(personaInquiryLoader);
    }
  }
  async function startKYCStripe(businessId) {
    const url = await api.vendor.getStripeHostedKYBUrl(businessId).then(res => res.data);
    openNewTab(url);
  }
  async function startKYC() {
    var _getState$personaKYBS;
    const worksAt = selectors.user.business(getState());
    const businessId = worksAt === null || worksAt === void 0 ? void 0 : worksAt.id;
    const stripeKycStatus = worksAt === null || worksAt === void 0 ? void 0 : worksAt.kycCompletionStatus;
    const personaKycStatus = (_getState$personaKYBS = getState().personaKYBStatus.data) === null || _getState$personaKYBS === void 0 ? void 0 : _getState$personaKYBS.status;
    if (businessId == null) {
      return;
    }
    if (stripeKycStatus === KycCompletionStatus.Completed && personaKycStatus === KYBProgressStatusEnum.DetailsNeeded || stripeKycStatus === KycCompletionStatus.InitialIncomplete) {
      startKYCPersona(businessId);
    } else {
      startKYCStripe(businessId);
    }
  }
  return {
    startKYC
  };
}