function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import groupBy from 'lodash/groupBy';
import first from 'lodash/first';
import map from 'lodash/map';
import flattenDeep from 'lodash/flattenDeep';
import { isAfter, isBefore, subMonths, addMonths, format } from 'date-fns';
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
import { daysOfWeek, defaultAgreementTitle, netTermsOptions } from "../../constants";
import { servicesCsvColumnConfig } from "../ImportServices/parseAndValidateServicesCsv";
import { addCsvLineNumbers, createErrorObject, getEmptyStringError, isBoolField, isBoolTrue, parseCsv, validateCsvColumns, validateCsvRow, invalidEmailError } from "../../components/ImportWizard/ImportWizard.utils";
import { RequestAccountantAccess } from "../../models/request-accountant-access";
import { serviceModel, legalTermsModel, DiscountModel, serviceCostModel, serviceBillingModel } from "../../models";
import { getUSPhoneNumberValidator, usPhoneNumberError } from 'validators';
import { fromDatestampObject, makeDatestampObject } from "../../models/time";
import Decimal from 'decimal.js';
import { makeAutoApprovalMethodNoticePeriod, makeManualApprovalMethodNoticePeriod } from "../../models/approval-method-notice-period";
import { getAutoApprovalRange, isAutoApprovalValid } from "../../business-logic/autoApprovalLogic";
import { decimalFromStr } from "../../infra/utils";
import { makeIntroVideoLink } from "../../models/intro-video/makers";
import { isUrlValid } from "../../components/AgreementStudio/IntroVideo/IntroVideoModal/IntroVIdeoModal.utils";
import { isValidNewOngoingBilling } from "../../models/service/newOngoingBilling";
import { convertStrToRichText } from 'seagull';
import { getPriceIncreaseDateRange } from "../../business-logic/priceIncreaseLogic";
import { PriceIncreaseModel, RoundPrices } from "../../models/PriceIncreaseModel";
import { validateAgreementRecipient, validateContact } from "../../business-logic/validators";
import { BillingDayOfMonthModel } from "../../models";
import { NewContactModel } from "../../models/ContactModel";
import { compact } from 'lodash';
import { makeFixedCost, makeRangeCost, makeVariableCost } from "../../models/service-cost";
import { makeBillUpfrontState, makeMaxCharges, makeOnApprovalBilling, makeOneTimeManualBilling, makeOngoingBilling, makeRepeatableManualBilling } from "../../models/service-billing";
const defaultCoverLetter = "Dear customer,\nWe've recently started using Anchor Automatic Billing as our billing service provider.\nThis allows us and you to save time and effort, as all invoices are generated automatically.\nSigning this digital agreement will allow your regular payments to happen automatically, and any changes to the agreement or to the payments will require your approval.\n";
export const dayOfMonthOptions = new Array(27).fill(null).map((_, idx) => idx + 1);
export const getInvalidBooleanError = _fieldName => 'invalid entry. Please ensure this column value is set to either "Yes" or "No"';
const paymentTermsError = 'Payment terms can be one of the following: ' + netTermsOptions.map((v, i) => i < netTermsOptions.length - 1 ? v : "or ".concat(v)).join(', ');
export const billingDayError = 'Invalid billing day. For monthly services, the billing day must be a numeric value between <i>1</i> and <i>27</i> or <i>last</i>. For weekly and biweekly services, it should be a day of the week (Monday - Sunday). All other services must not have a billing day set. Please adjust the billing day accordingly';
export const accountantAccessError = "Invalid entry. By filling out the column you request an Accountant access in the proposal, 'please enter <i>mandatory</i> or keep the field empty'";
export const optionalServiceError = 'invalid entry. Please select <i>Included</i> or <i>Not Included</i> to indicate the status of this optional service in the proposal. Leave it blank if the service is not optional';
export const sameAgreementUnmatchingDataError = 'invalid entry. Please ensure this value is set identically in all rows belonging to the same agreement';
export const termsAndConditionsError = 'Invalid terms and conditions file name';
export const billUpfrontLastDayError = 'Invalid entry. It is not possible to bill upfront on the last day of the month. Please change the billing day';
export const singlePackageError = 'Invalid entry. Agreements cannot have a single package. Ensure all services are assigned to a package, with either two or three packages in total';
export const tooManyPackagesError = 'Invalid entry. Agreements cannot have more than three packages. Please reduce the number of packages to three or fewer';
export const packageUnassignedServiceError = 'Invalid entry. All services must be assigned to a package or none at all. Please note, agreements cannot have more than three packages';
export const introVideoUrlError = "Invalid Entry. URL at intro video is invalid. Please enter a valid URL";
export const recipientDetailsIncompleteError = 'Invalid entry. Please ensure all recipient fields are filled out';
export const duplicateRecipientEmailError = 'There are multiple recipients using the same email address';
export const mainEmailSameAsRecipientError = 'Main email address cannot be the same as recipient email';
function configureValidation(validator) {
  return {
    validate: firstName => {
      const result = validator(firstName);
      return typeof result === 'string' ? {
        valid: false,
        errorType: result
      } : {
        valid: result
      };
    },
    validateError: (headerName, rowNumber, errorType) => createErrorObject(headerName, rowNumber, errorType || '')
  };
}
class DateParseResult {
  constructor(result) {
    this.result = result;
  }
  valid() {
    return this.result.valid;
  }
  datestamp() {
    return this.result.valid ? this.result.date : null;
  }
  date() {
    return this.result.valid && this.result.date ? fromDatestampObject(this.result.date) : null;
  }
  error() {
    return this.result.valid ? null : this.result.error;
  }
  toValidateResult() {
    return this.result.valid ? true : {
      valid: false,
      errorType: this.result.error
    };
  }
}
function parseDateString(dateString) {
  if (!dateString) {
    return new DateParseResult({
      valid: true,
      date: null
    });
  }
  const result1 = dateString.match(/^(?:((?:0?(?:1|3|5|7|8))|(?:10|12))[-/]((?:[012]?[0-9])|(?:3[01]))|(?:((?:0?(?:4|6|9))|(?:11))[-/]((?:[012]?[0-9])|(?:30)))|(?:(0?(?:2))[-/]([012]?[0-9])))[-/](\d{4})$/);
  if (result1) {
    const [, month1, day1, month2, day2, month3, day3, year] = result1;
    const month = month1 || month2 || month3;
    const day = day1 || day2 || day3;
    return new DateParseResult({
      valid: true,
      date: makeDatestampObject(+year, +month, +day)
    });
  }
  const result2 = dateString.match(/^(\d{4})[-/](?:((?:[012]?[0-9])|(?:3[01]))[-/]((?:0?(?:1|3|5|7|8))|(?:10|12))|(?:((?:[012]?[0-9])|(?:30))[-/]((?:0?(?:4|6|9))|(?:11)))|(?:([012]?[0-9])[-/](0?(?:2))))$/);
  if (result2) {
    const [, year, day1, month1, day2, month2, day3, month3] = result2;
    const month = month1 || month2 || month3;
    const day = day1 || day2 || day3;
    return new DateParseResult({
      valid: true,
      date: makeDatestampObject(+year, +month, +day)
    });
  }
  return new DateParseResult({
    valid: false,
    error: 'Enter a valid date in the following format: mm-dd-yyyy'
  });
}
function parseEffectiveDate(effectiveDate) {
  let now = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  const parsed = parseDateString(effectiveDate);
  const date = parsed.date();
  if (date && (isBefore(date, subMonths(now, 24)) || isAfter(date, addMonths(now, 12)))) {
    return new DateParseResult({
      valid: false,
      error: 'Date should be from 24 months in the past to 12 months in the future'
    });
  }
  return parsed;
}
function parsePriceIncreaseStartsOn(priceIncreaseStartsOn, effectiveDate, now) {
  const parsed = parseDateString(priceIncreaseStartsOn || '');
  const date = parsed.date();
  if (date) {
    const parsedEffectiveDate = parseEffectiveDate(effectiveDate, now);
    const {
      minDate,
      maxDate
    } = getPriceIncreaseDateRange(now, parsedEffectiveDate.date());
    if (isBefore(date, minDate) || isAfter(date, maxDate)) {
      const dateFormat = date => format(date, 'MM-dd-yyyy');
      return new DateParseResult({
        valid: false,
        error: "Date should be from ".concat(dateFormat(minDate), " to ").concat(dateFormat(maxDate))
      });
    }
  }
  return parsed;
}
const getAutoApprovalValidation = inputName => ({
  inputName,
  required: false,
  shouldMatchSameAgreementServices: true,
  validate(value) {
    const days = +value;
    const isValidAutoApprovalPeriod = !isNaN(days) && Number.isInteger(days) && isAutoApprovalValid(days);
    return value === '' || isValidAutoApprovalPeriod;
  },
  validateError: (headerName, rowNumber) => {
    const autoApprovalRange = getAutoApprovalRange();
    return createErrorObject(headerName, rowNumber, "Invalid notice period. Please select a number between ".concat(autoApprovalRange.minDays, " and ").concat(autoApprovalRange.maxDays));
  }
});
const minAutoPriceIncreaseRate = 0.01;
const maxAutoPriceIncreaseRate = 100;
const getCsvColumnConfig = () => _objectSpread(_objectSpread({}, servicesCsvColumnConfig), {}, {
  ['email']: _objectSpread(_objectSpread({
    inputName: 'email',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, invalidEmailError)
  }, configureValidation(validateContact.email)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['first name']: _objectSpread(_objectSpread({
    inputName: 'firstName',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName))
  }, configureValidation(validateContact.firstName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['last name']: _objectSpread(_objectSpread({
    inputName: 'lastName',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName))
  }, configureValidation(validateContact.lastName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['company name']: _objectSpread(_objectSpread({
    inputName: 'customerName',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getEmptyStringError(headerName))
  }, configureValidation(validateContact.companyName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['agreement title']: {
    inputName: 'agreementTitle',
    shouldMatchSameAgreementServices: true
  },
  ['payment terms']: {
    inputName: 'paymentTerms',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, paymentTermsError),
    validate: paymentTerms => {
      const isUponReceipt = (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.toLowerCase()) === 'upon receipt';
      const containsAnExpectedNumber = netTermsOptions.includes(parseInt(paymentTerms));
      return isUponReceipt || containsAnExpectedNumber;
    },
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, paymentTermsError),
    shouldMatchSameAgreementServices: true
  },
  ['require payment method']: {
    inputName: 'requirePaymentMethod',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    validate: isBoolField,
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    shouldMatchSameAgreementServices: true
  },
  ['email body']: {
    inputName: 'emailBody',
    shouldMatchSameAgreementServices: true
  },
  ['phone number']: {
    inputName: 'buyerPhone',
    validate: buyerPhone => !buyerPhone || getUSPhoneNumberValidator()('+1' + buyerPhone) === true,
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, usPhoneNumberError),
    shouldMatchSameAgreementServices: true
  },
  ['billed upfront']: {
    inputName: 'billedUpfront',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    validate: isBoolField,
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    shouldMatchSameAgreementServices: false
  },
  ['billing day']: {
    inputName: 'billingDay',
    required: false,
    validate: (billingDay, _ref) => {
      let {
        rowObject
      } = _ref;
      const isLast = billingDay.toLowerCase() === 'last';
      const containsAnExpectedNumber = dayOfMonthOptions.includes(parseInt(billingDay));
      const isDayOfWeek = daysOfWeek.map(day => day.toLowerCase()).includes(billingDay.toLowerCase());
      return rowObject.billingOccurrence.toLowerCase() === 'monthly' && (isLast || containsAnExpectedNumber) || ['weekly', 'biweekly'].includes(rowObject.billingOccurrence.toLowerCase()) && isDayOfWeek || billingDay === '';
    },
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, billingDayError),
    shouldMatchSameAgreementServices: false
  },
  ['effective date']: {
    inputName: 'effectiveDate',
    validate: (effectiveDate, _ref2) => {
      let {
        now
      } = _ref2;
      return parseEffectiveDate(effectiveDate, now).toValidateResult();
    },
    validateError: (headerName, rowNumber, errorType) => createErrorObject(headerName, rowNumber, errorType || ''),
    shouldMatchSameAgreementServices: true
  },
  ['accountant access']: {
    inputName: 'accountantAccess',
    required: true,
    requiredError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    validate: accountantAccess => isBoolField(accountantAccess),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    shouldMatchSameAgreementServices: true
  },
  ['mark this service as optional']: {
    inputName: 'optionalService',
    required: false,
    validate: optionalService => {
      return !optionalService || ['included', 'not included'].includes(optionalService.toLowerCase());
    },
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, optionalServiceError),
    shouldMatchSameAgreementServices: false
  },
  ['terms and conditions']: {
    inputName: 'termsAndConditions',
    required: false,
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, termsAndConditionsError),
    shouldMatchSameAgreementServices: true
  },
  ['issue invoice on acceptance']: {
    inputName: 'issueInvoiceOnAcceptance',
    validate: issueInvoiceOnAcceptance => isBoolField(issueInvoiceOnAcceptance),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName))
  },
  ['pause automatic billing']: {
    inputName: 'startPaused',
    validate: startPaused => isBoolField(startPaused),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName))
  },
  ['agreement note']: {
    inputName: 'extraClause',
    shouldMatchSameAgreementServices: true
  },
  ['amendments auto approval']: getAutoApprovalValidation('amendmentsAutoApproval'),
  ['packages']: {
    inputName: 'packages',
    required: false
  },
  ['intro video']: {
    inputName: 'introVideoUrl',
    required: false,
    shouldMatchSameAgreementServices: true,
    validate: introVideoUrl => {
      if (introVideoUrl === '') {
        return true;
      }
      return isUrlValid(introVideoUrl);
    },
    validateError: (headerName, rowNumber) => {
      return createErrorObject(headerName, rowNumber, introVideoUrlError);
    }
  },
  ['automatic yearly price increase rate']: {
    inputName: 'priceIncreaseRate',
    validate(priceIncreaseRate) {
      if (!priceIncreaseRate) {
        return true;
      }
      const asNumber = +priceIncreaseRate;
      return !isNaN(asNumber) && asNumber >= minAutoPriceIncreaseRate && asNumber <= maxAutoPriceIncreaseRate;
    },
    validateError(headerName, rowNumber) {
      return createErrorObject(headerName, rowNumber, "Invalid price increase rate. Please enter a number between ".concat(minAutoPriceIncreaseRate, " and ").concat(maxAutoPriceIncreaseRate));
    }
  },
  ['automatic yearly price increase starts on']: {
    inputName: 'priceIncreaseStartsOn',
    validate: (priceIncreaseStartsOn, _ref3) => {
      let {
        now = new Date(),
        rowObject: {
          effectiveDate
        }
      } = _ref3;
      return parsePriceIncreaseStartsOn(priceIncreaseStartsOn, effectiveDate, now).toValidateResult();
    },
    validateError: (headerName, rowNumber, errorType) => createErrorObject(headerName, rowNumber, errorType || '')
  },
  ['out of scope charges auto approval']: getAutoApprovalValidation('invoicesAutoApproval'),
  ['recipient 1 first name']: _objectSpread(_objectSpread({
    inputName: 'recipient1FirstName',
    required: false
  }, configureValidation(validateAgreementRecipient.firstName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 1 last name']: _objectSpread(_objectSpread({
    inputName: 'recipient1LastName',
    required: false
  }, configureValidation(validateAgreementRecipient.lastName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 1 email']: _objectSpread(_objectSpread({
    inputName: 'recipient1Email',
    required: false
  }, configureValidation(validateAgreementRecipient.email)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 1 required to sign']: {
    inputName: 'recipient1RequiredToSign',
    required: false,
    validate: requiredToSign => !requiredToSign || isBoolField(requiredToSign),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    shouldMatchSameAgreementServices: true
  },
  ['recipient 2 first name']: _objectSpread(_objectSpread({
    inputName: 'recipient2FirstName',
    required: false
  }, configureValidation(validateAgreementRecipient.firstName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 2 last name']: _objectSpread(_objectSpread({
    inputName: 'recipient2LastName',
    required: false
  }, configureValidation(validateAgreementRecipient.lastName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 2 email']: _objectSpread(_objectSpread({
    inputName: 'recipient2Email',
    required: false
  }, configureValidation(validateAgreementRecipient.email)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 2 required to sign']: {
    inputName: 'recipient2RequiredToSign',
    required: false,
    validate: requiredToSign => !requiredToSign || isBoolField(requiredToSign),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    shouldMatchSameAgreementServices: true
  },
  ['recipient 3 first name']: _objectSpread(_objectSpread({
    inputName: 'recipient3FirstName',
    required: false
  }, configureValidation(validateAgreementRecipient.firstName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 3 last name']: _objectSpread(_objectSpread({
    inputName: 'recipient3LastName',
    required: false
  }, configureValidation(validateAgreementRecipient.lastName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 3 email']: _objectSpread(_objectSpread({
    inputName: 'recipient3Email',
    required: false
  }, configureValidation(validateAgreementRecipient.email)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 3 required to sign']: {
    inputName: 'recipient3RequiredToSign',
    required: false,
    validate: requiredToSign => !requiredToSign || isBoolField(requiredToSign),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    shouldMatchSameAgreementServices: true
  },
  ['recipient 4 first name']: _objectSpread(_objectSpread({
    inputName: 'recipient4FirstName',
    required: false
  }, configureValidation(validateAgreementRecipient.firstName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 4 last name']: _objectSpread(_objectSpread({
    inputName: 'recipient4LastName',
    required: false
  }, configureValidation(validateAgreementRecipient.lastName)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 4 email']: _objectSpread(_objectSpread({
    inputName: 'recipient4Email',
    required: false
  }, configureValidation(validateAgreementRecipient.email)), {}, {
    shouldMatchSameAgreementServices: true
  }),
  ['recipient 4 required to sign']: {
    inputName: 'recipient4RequiredToSign',
    required: false,
    validate: requiredToSign => !requiredToSign || isBoolField(requiredToSign),
    validateError: (headerName, rowNumber) => createErrorObject(headerName, rowNumber, getInvalidBooleanError(headerName)),
    shouldMatchSameAgreementServices: true
  }
});
function getIsPaused(rowObject) {
  return isBoolTrue(rowObject.startPaused || '');
}
function getSkipBillingOnAcceptance(rowObject) {
  return !isBoolTrue(rowObject.issueInvoiceOnAcceptance || '');
}
function getIsRecurring(rowObject) {
  var _rowObject$billingOcc;
  return ((_rowObject$billingOcc = rowObject.billingOccurrence) === null || _rowObject$billingOcc === void 0 ? void 0 : _rowObject$billingOcc.toLowerCase()) !== 'one time';
}
function runValidation(data, availableLegalTerms, now, csvColumnConfig) {
  const errorContext = {
    phase: 'validation'
  };
  if (!data.length) throw new Error('No data');
  validateCsvColumns(data, csvColumnConfig);
  const withLineNumbers = addCsvLineNumbers(data);
  const groupedServicesByAgreement = groupBy(withLineNumbers, _ref4 => {
    let {
      email,
      customerName
    } = _ref4;
    return "".concat(email, ":").concat(customerName);
  });
  const errors = map(groupedServicesByAgreement, serviceRows => {
    const [packageNames, hasServicesWithoutPackageName] = serviceRows.reduce((acc, rowObject) => {
      const emptyPackageName = rowObject.packages === '';
      return [!emptyPackageName ? acc[0].add(rowObject.packages) : acc[0], acc[1] || emptyPackageName];
    }, [new Set([]), false]);
    if (packageNames.size === 1) {
      return createErrorObject('packages', serviceRows[0].lineNumber, singlePackageError);
    }
    if (packageNames.size > 3) {
      return createErrorObject('packages', serviceRows[0].lineNumber, tooManyPackagesError);
    }
    if (packageNames.size > 0 && hasServicesWithoutPackageName) {
      return createErrorObject('packages', serviceRows[0].lineNumber, packageUnassignedServiceError);
    }
    return serviceRows.map(rowObject => {
      errorContext['service'] = rowObject.serviceName;
      errorContext['lineNumber'] = rowObject.lineNumber;
      const isInvalidRecipientFields = fields => fields.some((val, _, arr) => !!val !== !!arr[0]);
      try {
        const recipientEmails = compact([rowObject.recipient1Email, rowObject.recipient2Email, rowObject.recipient3Email, rowObject.recipient4Email]);
        if (new Set(recipientEmails).size !== recipientEmails.length) {
          return createErrorObject('recipient emails', rowObject.lineNumber, duplicateRecipientEmailError);
        }
        if (recipientEmails.includes(rowObject.email)) {
          return createErrorObject('email', rowObject.lineNumber, mainEmailSameAsRecipientError);
        }
        if (isInvalidRecipientFields([rowObject.recipient1Email, rowObject.recipient1FirstName, rowObject.recipient1LastName, rowObject.recipient1RequiredToSign])) return createErrorObject('recipient 1', rowObject.lineNumber, recipientDetailsIncompleteError);
        if (isInvalidRecipientFields([rowObject.recipient2Email, rowObject.recipient2FirstName, rowObject.recipient2LastName, rowObject.recipient2RequiredToSign])) return createErrorObject('recipient 2', rowObject.lineNumber, recipientDetailsIncompleteError);
        if (isInvalidRecipientFields([rowObject.recipient3Email, rowObject.recipient3FirstName, rowObject.recipient3LastName, rowObject.recipient3RequiredToSign])) return createErrorObject('recipient 3', rowObject.lineNumber, recipientDetailsIncompleteError);
        if (isInvalidRecipientFields([rowObject.recipient4Email, rowObject.recipient4FirstName, rowObject.recipient4LastName, rowObject.recipient4RequiredToSign])) return createErrorObject('recipient 4', rowObject.lineNumber, recipientDetailsIncompleteError);
        return validateCsvRow(rowObject, csvColumnConfig, (columnConfig, columnName) => {
          const {
            inputName,
            shouldMatchSameAgreementServices
          } = columnConfig;
          const {
            lineNumber,
            billedUpfront,
            automaticBilling,
            issueInvoiceOnAcceptance,
            billingDay,
            effectiveDate,
            priceIncreaseRate
          } = rowObject;
          const value = rowObject[inputName];
          const isRecurring = getIsRecurring(rowObject);
          const isAutomaticBilling = isBoolTrue(automaticBilling);
          const isOngoing = isRecurring && isAutomaticBilling;
          const isBilledUpfront = isBoolTrue(billedUpfront);
          const isPaused = getIsPaused(rowObject);
          const isValidSkipBillingOnAcceptance = isBoolField(issueInvoiceOnAcceptance || '');
          const skipBillingOnAcceptance = getSkipBillingOnAcceptance(rowObject);
          if (shouldMatchSameAgreementServices && value !== serviceRows[0][inputName]) {
            return createErrorObject(columnName, lineNumber, sameAgreementUnmatchingDataError);
          }
          if (inputName === 'termsAndConditions' && typeof value === 'string' && value !== '' && !availableLegalTerms.some(doc => legalTermsModel.name(doc).toLowerCase() === value.toLowerCase() || legalTermsModel.name(doc).toLowerCase() === value.toLowerCase() + '.pdf')) {
            return createErrorObject(columnName, lineNumber, termsAndConditionsError);
          }
          if (inputName === 'billingOccurrence' && typeof value === 'string' && value.toLowerCase() === 'monthly' && billingDay === '') {
            return createErrorObject(columnName, lineNumber, billingDayError);
          }
          if (inputName === 'billingOccurrence' && typeof value === 'string' && (value.toLowerCase() === 'weekly' || value.toLowerCase() === 'biweekly') && billingDay === '') {
            return createErrorObject(columnName, lineNumber, billingDayError);
          }
          if (inputName === 'billingDay' && isBilledUpfront && typeof value === 'string' && value.toLowerCase() === 'last') {
            return createErrorObject(columnName, lineNumber, billUpfrontLastDayError);
          }
          if (inputName === 'issueInvoiceOnAcceptance' && isValidSkipBillingOnAcceptance && isOngoing && !isValidNewOngoingBilling({
            isBilledUpfront,
            isPaused,
            isProrated: false,
            billUpfrontState: makeBillUpfrontState(skipBillingOnAcceptance)
          })) {
            return createErrorObject(columnName, lineNumber, 'You can issue an invoice on acceptance only for automatic, recurring, unpaused, billed-upfront services');
          }
          if ((inputName === 'priceIncreaseRate' || inputName === 'priceIncreaseStartsOn') && !!value && !isRecurring) {
            return createErrorObject(columnName, lineNumber, 'Automatic yearly price increase is only available for recurring services');
          }
          if (inputName === 'priceIncreaseStartsOn') {
            if (!value && priceIncreaseRate && effectiveDate) {
              return createErrorObject(columnName, lineNumber, 'Automatic yearly price increase start date is required if effective date is set');
            }
            if (value && !priceIncreaseRate) {
              return createErrorObject(columnName, lineNumber, 'Automatic yearly price increase rate is required if start date is set');
            }
          }
        }, now);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (exn) {
        console.error({
          error: '' + exn,
          errorContext
        });
        if ((exn === null || exn === void 0 ? void 0 : exn.message) != null) {
          errorContext['exn'] = exn.message;
          exn.message = JSON.stringify(errorContext);
        }
        throw exn;
      }
    });
  });
  return flattenDeep(errors);
}
const createServiceFromCsvRow = (rowObject, now) => {
  const {
    serviceName,
    serviceDescription,
    optionalService
  } = rowObject;
  return {
    id: generateUniqueId('service'),
    name: serviceName,
    description: convertStrToRichText(serviceDescription),
    billing: serviceBillingModel.toDTO(makeServiceBilling(rowObject)),
    cost: serviceCostModel.toDTO(makeServiceCost(rowObject, now)),
    participation: makeServiceParticipation(optionalService),
    isBillable: false,
    serviceTemplateId: null,
    status: serviceModel.statusToDTO(serviceModel.makeAwaitingApprovalStatus())
  };
};
function makeServiceParticipation(optionalService) {
  const normalizedOptionalService = optionalService === null || optionalService === void 0 ? void 0 : optionalService.toLowerCase().trim();
  if (['included', 'not included'].includes(normalizedOptionalService)) {
    const includedByDefault = normalizedOptionalService === 'included';
    return {
      type: 'optional',
      optional: {
        includedByDefault
      }
    };
  } else {
    return {
      type: 'mandatory'
    };
  }
}
function makeServiceCost(rowObject, now) {
  const {
    pricing,
    rate,
    discountType,
    unitName,
    unitCap,
    effectiveDate,
    priceIncreaseRate,
    priceIncreaseStartsOn
  } = rowObject;
  const discount = decimalFromStr(rowObject.discount);
  const priceIncrease = priceIncreaseRate ? PriceIncreaseModel.make(RoundPrices.None, new Decimal(priceIncreaseRate), parsePriceIncreaseStartsOn(priceIncreaseStartsOn, effectiveDate, now).date()) : PriceIncreaseModel.makeNone();
  const discountObject = discount != null && discount ? (discountType === null || discountType === void 0 ? void 0 : discountType.toLowerCase()) === 'flat' ? DiscountModel.makeValue(discount.toNumber()) : DiscountModel.makePercent(discount.toNumber()) : null;
  switch (pricing === null || pricing === void 0 ? void 0 : pricing.toLowerCase()) {
    case 'fixed':
      {
        return makeFixedCost(decimalFromStr(rate), {
          discount: discountObject,
          priceIncrease
        });
      }
    case 'hourly':
    case 'quantity':
      return makeVariableCost(decimalFromStr(rate), {
        unitName,
        unitCap: decimalFromStr(unitCap) || new Decimal(0),
        discount: discountObject,
        priceIncrease
      });
    case 'range':
      {
        const [first, second] = rate.split('-').map(p => p.trim());
        const hasMin = Boolean(second); // when there is only one number > it is the maximum price
        return makeRangeCost(decimalFromStr(second || first), {
          minPrice: hasMin ? decimalFromStr(first) : null,
          discount: discountObject,
          priceIncrease
        });
      }
    default:
      throw new Error('bad service pricing configuration');
  }
}
function makeServiceBilling(rowObject) {
  const {
    billedUpfront,
    automaticBilling,
    billingOccurrence,
    billingDay
  } = rowObject;
  const isRecurring = getIsRecurring(rowObject);
  const isAutomaticBilling = isBoolTrue(automaticBilling);
  const isMonthly = 'monthly' === billingOccurrence.toLowerCase();
  const isWeekly = ['weekly', 'biweekly'].includes(billingOccurrence.toLowerCase());
  const isBilledUpfront = isBoolTrue(billedUpfront);
  const billingDayOfMonth = isMonthly ? makeBillingDayOfMonth(billingDay) : null;
  const billingDayOfWeek = isWeekly ? billingDay : null;
  const recurrencePeriod = billingOccurrence.toLowerCase();
  const isPaused = getIsPaused(rowObject);
  const skipBillingOnAcceptance = getSkipBillingOnAcceptance(rowObject);
  return isAutomaticBilling && !isRecurring ? makeOnApprovalBilling() : !isAutomaticBilling && !isRecurring ? makeOneTimeManualBilling() : !isAutomaticBilling && isRecurring ? makeRepeatableManualBilling(recurrencePeriod, {
    billingDayOfMonth,
    billingDayOfWeek,
    isBilledUpfront,
    maxCharges: makeMaxCharges(false)
  }) : makeOngoingBilling(recurrencePeriod, {
    isAwaitingApproval: true,
    billingDayOfMonth,
    billingDayOfWeek,
    maxCharges: makeMaxCharges(false),
    isProrated: false,
    isPaused,
    isBilledUpfront,
    billUpfrontState: makeBillUpfrontState(skipBillingOnAcceptance)
  });
}
function makeBillingDayOfMonth(billingDay) {
  return BillingDayOfMonthModel.make(billingDay.toLowerCase() === 'last' ? 'last' : parseInt(billingDay));
}
const createDraft = function createDraft(rows, defaultDraftsValues, availableLegalTerms) {
  let now = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : new Date();
  const errorContext = {};
  try {
    const firstRow = first(rows);
    if (firstRow == null) throw new Error('empty CSV');
    const {
      email,
      firstName,
      lastName,
      buyerPhone,
      customerName,
      agreementTitle,
      paymentTerms,
      requirePaymentMethod,
      emailBody,
      effectiveDate,
      accountantAccess,
      termsAndConditions,
      amendmentsAutoApproval,
      extraClause,
      invoicesAutoApproval,
      recipient1Email,
      recipient1FirstName,
      recipient1LastName,
      recipient1RequiredToSign,
      recipient2Email,
      recipient2FirstName,
      recipient2LastName,
      recipient2RequiredToSign,
      recipient3Email,
      recipient3FirstName,
      recipient3LastName,
      recipient3RequiredToSign,
      recipient4Email,
      recipient4FirstName,
      recipient4LastName,
      recipient4RequiredToSign
    } = firstRow;
    errorContext['email'] = email;
    errorContext['customerName'] = customerName;
    errorContext['agreementTitle'] = agreementTitle;
    const isUponReceipt = (paymentTerms === null || paymentTerms === void 0 ? void 0 : paymentTerms.toLowerCase()) === 'upon receipt';
    const netTerms = isUponReceipt ? 0 : parseInt(paymentTerms);
    const packages = groupBy(rows, _ref5 => {
      let {
        packages
      } = _ref5;
      return packages;
    });
    const packageNames = Object.keys(packages);
    const serviceBundles = packageNames.reduce((acc, packageName) => {
      const packageRows = packages[packageName];
      const services = packageRows.map(r => {
        errorContext['phase'] = 'create-service';
        errorContext['service'] = r.serviceName;
        return createServiceFromCsvRow(r, now);
      });
      acc.bundles.push({
        id: generateUniqueId('bundle'),
        name: packageName,
        services
      });
      return acc;
    }, {
      bundles: []
    });
    errorContext['phase'] = undefined;
    errorContext['service'] = undefined;
    const parsedEffectiveDate = parseEffectiveDate(effectiveDate, now);
    const legalTermsDoc = availableLegalTerms.find(doc => legalTermsModel.name(doc).toLowerCase() === termsAndConditions.toLowerCase() || legalTermsModel.name(doc).toLowerCase() === termsAndConditions.toLowerCase() + '.pdf');
    return {
      draft: {
        id: generateUniqueId('draft'),
        coverLetter: defaultCoverLetter,
        extraClause: convertStrToRichText(extraClause || ''),
        requirePaymentMethod: isBoolTrue(requirePaymentMethod),
        allowManualPayments: false,
        isExistingCustomer: false,
        customNotificationText: (emailBody === null || emailBody === void 0 ? void 0 : emailBody.length) > 0 ? emailBody : '',
        title: agreementTitle.length > 0 ? agreementTitle : defaultAgreementTitle,
        lastModifiedOn: Date.now(),
        netTerms,
        effectiveDate: parsedEffectiveDate.datestamp(),
        legalTerms: legalTermsDoc && legalTermsModel.toDraftLegalTerms(legalTermsDoc, false) || defaultDraftsValues.legalTerms || null,
        feeControls: {
          coverCreditCardFees: false,
          coverDirectDebitFees: false
        },
        creationStrategy: null,
        clientContactId: null,
        isSample: false,
        amendmentsApprovalMethod: amendmentsAutoApproval ? makeAutoApprovalMethodNoticePeriod(parseInt(amendmentsAutoApproval)) : makeManualApprovalMethodNoticePeriod(),
        invoicesApprovalMethod: invoicesAutoApproval ? makeAutoApprovalMethodNoticePeriod(parseInt(invoicesAutoApproval)) : makeManualApprovalMethodNoticePeriod(),
        requestAccountantAccess: isBoolTrue(accountantAccess) ? RequestAccountantAccess.Mandatory : RequestAccountantAccess.None,
        serviceBundles: packageNames.length > 1 ? serviceBundles : undefined,
        services: packageNames.length > 1 ? [] : serviceBundles.bundles[0].services,
        reviewers: compact([recipient1Email && recipient1FirstName && recipient1LastName && recipient1RequiredToSign && {
          email: recipient1Email,
          firstName: recipient1FirstName,
          lastName: recipient1LastName,
          isSignatory: isBoolTrue(recipient1RequiredToSign)
        }, recipient2Email && recipient2FirstName && recipient2LastName && recipient2RequiredToSign && {
          email: recipient2Email,
          firstName: recipient2FirstName,
          lastName: recipient2LastName,
          isSignatory: isBoolTrue(recipient2RequiredToSign)
        }, recipient3Email && recipient3FirstName && recipient3LastName && recipient3RequiredToSign && {
          email: recipient3Email,
          firstName: recipient3FirstName,
          lastName: recipient3LastName,
          isSignatory: isBoolTrue(recipient3RequiredToSign)
        }, recipient4Email && recipient4FirstName && recipient4LastName && recipient4RequiredToSign && {
          email: recipient4Email,
          firstName: recipient4FirstName,
          lastName: recipient4LastName,
          isSignatory: isBoolTrue(recipient4RequiredToSign)
        }]),
        introVideo: rows[0].introVideoUrl ? makeIntroVideoLink(rows[0].introVideoUrl) : null
      },
      contact: NewContactModel.make({
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: buyerPhone ? '+1' + buyerPhone : null,
        companyName: customerName
      })
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (exn) {
    console.error({
      error: '' + exn,
      errorContext
    });
    if ((exn === null || exn === void 0 ? void 0 : exn.message) != null) {
      errorContext['exn'] = exn.message;
      exn.message = JSON.stringify(errorContext);
    }
    throw exn;
  }
};
const createDrafts = _ref6 => {
  let {
    parsedCsv,
    defaultDraftsValues,
    availableLegalTerms,
    now
  } = _ref6;
  const groupedServicesByAgreement = groupBy(parsedCsv, _ref7 => {
    let {
      email,
      customerName
    } = _ref7;
    return "".concat(email, ":").concat(customerName);
  });
  return map(groupedServicesByAgreement, serviceRows => createDraft(serviceRows, defaultDraftsValues, availableLegalTerms, now));
};
function tryToParseError(error, or) {
  try {
    return JSON.parse(error);
  } catch (e) {
    return or;
  }
}
export const parseAndValidateAgreementCsv = async _ref8 => {
  let {
    data,
    defaultDraftsValues,
    availableLegalTerms,
    now
  } = _ref8;
  try {
    const csvColumnConfig = getCsvColumnConfig();
    const parsedCsv = await parseCsv(data, csvColumnConfig);
    const errors = runValidation(parsedCsv, availableLegalTerms, now, csvColumnConfig);
    if (!errors.length) {
      const items = createDrafts({
        parsedCsv,
        defaultDraftsValues,
        availableLegalTerms,
        now
      });
      return {
        items,
        errors: []
      };
    } else {
      return {
        items: [],
        errors
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (exn) {
    var _tryToParseError;
    return {
      items: [],
      errors: [{
        message: '' + exn,
        rowNumber: ((_tryToParseError = tryToParseError(exn === null || exn === void 0 ? void 0 : exn.message, 0)) === null || _tryToParseError === void 0 ? void 0 : _tryToParseError.lineNumber) || 0
      }]
    };
  }
};