import { useMemo } from 'react';
import { useGlobalContext, useStoreActions } from "../contexts";
import { handleRequest } from "../infra/utils";
export function useQboIntegration() {
  const {
    api
  } = useStoreActions();
  const {
    showErrorToast
  } = useGlobalContext();
  return useMemo(() => {
    return {
      async connectToQbo(redirectUrl) {
        const qboIntegration = await handleRequest(api.qbo.connect(redirectUrl));
        if (!qboIntegration.success) {
          showErrorToast('Failed to connect', 'failed-to-connect-toast');
        } else {
          return qboIntegration.data;
        }
      },
      async disconnectFromQbo() {
        const integration = await handleRequest(api.qbo.disconnect());
        if (!integration.success) {
          showErrorToast('Failed to disconnect', 'failed-to-disconnect-toast');
        } else {
          return integration.data;
        }
      },
      async updateQboSettings(settings) {
        const result = await handleRequest(api.qbo.updateSettings(settings));
        if (!result.success) {
          showErrorToast('Failed to update integration settings', 'failed-to-sync-toast');
          return result;
        }
        return result;
      }
    };
  }, []);
}