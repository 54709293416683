import * as relationshipModel from "./relationship";
export class AgreementDataModel {
  static make(data) {
    return new AgreementDataModel(data.netTerms, data.autoPayment, data.isActiveAgreement, data.effectiveDate);
  }
  static fromRelationship(relationship) {
    return new AgreementDataModel(relationshipModel.actualNetTerms(relationship), !relationship.allowManualPayments, !relationshipModel.isPending(relationship), relationship.effectiveDate);
  }
  constructor(netTerms, autoPayment, isActiveAgreement, effectiveDate) {
    this.netTerms = netTerms;
    this.autoPayment = autoPayment;
    this.isActiveAgreement = isActiveAgreement;
    this.effectiveDate = effectiveDate;
  }
  setNetTerms(netTerms) {
    return new AgreementDataModel(netTerms, this.autoPayment, this.isActiveAgreement, this.effectiveDate);
  }
}