function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { BillingTriggerType } from "../../types/api.model";
import { RecurrencePeriodTypes } from "./model";
import { defaultBillingDayOfWeek } from "../../constants";
import { BillingDayOfMonthModel } from "../BillingDayOfMonth";
import { getValidNewOngoingBilling } from "../service/newOngoingBilling";
import * as selectors from "./selectors";
export function makeOnApprovalBilling() {
  return {
    trigger: BillingTriggerType.OnApproval
  };
}
export function makeOneTimeManualBilling() {
  return {
    trigger: BillingTriggerType.OneTimeManual
  };
}
export function makeOneTimeDeliveryBilling() {
  return {
    trigger: BillingTriggerType.OneTimeDelivery
  };
}
export function makeRepeatableManualBilling(period) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    billingDayOfMonth,
    billingDayOfWeek,
    isBilledUpfront = false,
    maxCharges
  } = options;
  return {
    trigger: BillingTriggerType.RepeatableManual,
    recurrencePeriod: period,
    isBilledUpfront,
    billingDayOfMonth: period === RecurrencePeriodTypes.Monthly ? billingDayOfMonth || BillingDayOfMonthModel.make() : null,
    billingDayOfWeek: selectors.periodRequiresBillingDayOfWeek(period) ? billingDayOfWeek || defaultBillingDayOfWeek : null,
    maxCharges: maxCharges != null ? maxCharges : makeMaxCharges(false)
  };
}
export function makeOngoingBilling(period) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    billingDayOfMonth,
    billingDayOfWeek,
    isPaused = false,
    isProrated = false,
    isBilledUpfront = false,
    billUpfrontState = makeBillUpfrontState(false),
    maxCharges,
    isAwaitingApproval
  } = options;
  return _objectSpread({
    trigger: BillingTriggerType.Ongoing,
    recurrencePeriod: period,
    billingDayOfMonth: period === RecurrencePeriodTypes.Monthly ? billingDayOfMonth || BillingDayOfMonthModel.make() : null,
    billingDayOfWeek: selectors.periodRequiresBillingDayOfWeek(period) ? billingDayOfWeek || defaultBillingDayOfWeek : null,
    maxCharges: maxCharges != null ? maxCharges : makeMaxCharges(false)
  }, isAwaitingApproval ? getValidNewOngoingBilling({
    isPaused,
    isProrated,
    isBilledUpfront,
    billUpfrontState
  }) : {
    isPaused,
    isProrated,
    isBilledUpfront,
    billUpfrontState
  });
}
export function makeMaxCharges(enabled, limit) {
  return {
    enabled,
    limit: enabled ? limit || null : null
  };
}
export function makeBillUpfrontState(skipBillingOnAcceptance) {
  return {
    skipBillingOnAcceptance
  };
}