import compact from 'lodash/compact';
import { DiscountModel, serviceBillingModel, serviceCostModel, serviceModel } from "../../models";
import { BillingTriggerType } from "../../types/api.model";
import { ServiceStatusType } from "../../models/service/model";
import { pluralizeUnitName } from "../../utils/unit-name.utils";
import { getServiceBillingText, getServicePaymentText } from "../../utils/service-description";
import { ServiceCostType } from "../../models/service-cost";
import { utils } from "../../utils";
export function getServiceDescriptionBadges(service, isActiveAgreement) {
  const {
    billing,
    cost,
    participation
  } = service;
  const {
    discount
  } = cost;
  return compact([(() => {
    switch (billing.trigger) {
      case BillingTriggerType.OnApproval:
        return isActiveAgreement ? {
          text: 'Billed once, automatically',
          intent: 'type-approval-amendment'
        } : {
          text: 'Billed once, on agreement approval',
          intent: 'type-approval'
        };
      case BillingTriggerType.OneTimeDelivery:
        return {
          text: 'Billed once, on approval of delivery',
          intent: 'type-delivery'
        };
      case BillingTriggerType.OneTimeManual:
        return {
          text: 'Billed once, manually',
          intent: 'type-onetime-manual'
        };
      case BillingTriggerType.RepeatableManual:
        return {
          text: "Billed ".concat(billing.recurrencePeriod, ", manually"),
          intent: 'type-repeatable-manual'
        };
      case BillingTriggerType.Ongoing:
      default:
        return {
          text: "Billed ".concat(billing.recurrencePeriod, ", automatically"),
          intent: 'type-ongoing'
        };
    }
  })(), discount && discount.amount().gt(0) && {
    skin: 'success',
    intent: "".concat(discount.type(), "-discount"),
    text: "".concat(discount.format(), " Off")
  }, cost.pricingType === 'variable' && cost.unitCap && cost.unitCap.gt(0) && {
    skin: 'success',
    intent: 'preapproved',
    text: "".concat(cost.unitCap.toString(), " ").concat(pluralizeUnitName(cost.unitCap, cost.unitName), " preapproved")
  }, 'isProrated' in billing && billing.isProrated && {
    skin: 'warning',
    intent: 'prorated',
    text: 'Prorated',
    tooltip: 'This service is prorated'
  }, 'isProrated' in billing && billing.isPaused && {
    skin: 'warning',
    intent: 'paused',
    text: 'Paused',
    tooltip: 'Automatic billing is paused for this service'
  }, participation.type === 'optional' && {
    skin: 'warning',
    intent: 'optional',
    text: 'Optional',
    tooltip: 'This service is marked as optional'
  }, serviceModel.maxChargesEnabled(service) && serviceModel.validateMaxCharges(service).length === 0 && {
    skin: 'warning',
    intent: 'limit',
    text: "Can be billed ".concat(serviceModel.maxChargesLimit(service) === 1 ? 'once' : serviceModel.maxChargesLimit(service) + ' times'),
    tooltip: 'This service has a limited number of charges'
  }, serviceBillingModel.isOngoing(serviceModel.billingTrigger(service)) && serviceModel.isBilledUpfront(service) && !serviceModel.isSkipBillingOnAcceptance(service) && {
    skin: 'warning',
    intent: isActiveAgreement ? 'bill-immediately' : 'bill-on-approval',
    text: "Charged ".concat(isActiveAgreement ? 'immediately' : 'on acceptance'),
    tooltip: "This service will be billed ".concat(isActiveAgreement ? 'immediately' : 'on agreement approval')
  }, serviceModel.priceIncrease(service).exists() && {
    skin: 'warning',
    intent: 'price-increase',
    text: 'Auto-price increase'
  }]);
}
export function getStatusBadgeText(serviceType, isActiveAgreement, disableStatusBadge) {
  if (disableStatusBadge) {
    return null;
  }
  switch (serviceType) {
    case ServiceStatusType.Amended:
      return 'Amended';
    case ServiceStatusType.AwaitingApproval:
      return isActiveAgreement ? 'New Service' : null;
    case ServiceStatusType.Completed:
      return 'Completed';
    case ServiceStatusType.Terminated:
      return 'Stopped';
    case ServiceStatusType.Canceled:
      return 'Canceled';
    default:
      return null;
  }
}
export function getDiscountString(discount) {
  const normalized = discount || DiscountModel.makeValue(0);
  return normalized.switch({
    whenPercent: () => "".concat(normalized.amount(), "% Off"),
    whenValue: () => "$".concat(normalized.amount(), " Off")
  });
}
export function getBulletPoints(service, isActiveAgreement, agreementData) {
  const {
    cost
  } = service;
  const isVariablePrice = serviceCostModel.isVariablePrice(cost);
  const isRangePrice = serviceCostModel.isRangePrice(cost);
  const billingText = getServiceBillingText(service, {
    isActiveAgreement,
    effectiveDate: agreementData === null || agreementData === void 0 ? void 0 : agreementData.effectiveDate
  });
  return billingText.concat(getServicePaymentText({
    unitCap: isVariablePrice ? cost.unitCap : undefined,
    unitName: isVariablePrice ? cost.unitName : undefined,
    maxPrice: isRangePrice ? cost.maxPrice : undefined
  }, agreementData));
}
export function getPriceDisplayProps(service) {
  const {
    cost
  } = service;
  const discount = cost.discount;
  const getRangePriceString = (minPrice, maxPrice) => minPrice === null || minPrice.isZero() ? "Up to ".concat(utils.formatDollarAmount(maxPrice)) : "".concat(utils.formatDollarAmount(minPrice), " - ").concat(utils.formatDollarAmount(maxPrice));
  switch (cost.pricingType) {
    case ServiceCostType.Fixed:
      return {
        priceAfterDiscount: utils.formatDollarAmount(utils.applyDiscount(cost.price, discount)),
        priceBeforeDiscount: discount ? utils.formatDollarAmount(cost.price) : undefined
      };
    case ServiceCostType.Variable:
      return {
        priceAfterDiscount: utils.formatDollarAmount(utils.applyDiscount(cost.price, discount)),
        priceBeforeDiscount: discount ? utils.formatDollarAmount(cost.price) : undefined,
        postfixPrice: "/".concat(cost.unitName || 'hr')
      };
    case ServiceCostType.Range:
      {
        const finalMaxPrice = utils.applyDiscount(cost.maxPrice, discount);
        const priceAfterDiscount = getRangePriceString(cost.minPrice ? utils.applyDiscount(cost.minPrice, discount) : null, finalMaxPrice);
        const priceBeforeDiscount = discount ? getRangePriceString(cost.minPrice, cost.maxPrice) : undefined;
        return {
          priceAfterDiscount,
          priceBeforeDiscount
        };
      }
  }
}