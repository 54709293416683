import Decimal from 'decimal.js';
import { PriceIncreaseModel } from "../PriceIncreaseModel";
import { ServiceCostType } from "./model";
export function makeFixedCost(price) {
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    pricingType: ServiceCostType.Fixed,
    price: new Decimal(price),
    discount: opts.discount || null,
    priceIncrease: opts.priceIncrease || PriceIncreaseModel.makeNone()
  };
}
export function makeVariableCost(price) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    unitCap,
    unitName,
    discount,
    priceIncrease
  } = options;
  return {
    pricingType: ServiceCostType.Variable,
    price: new Decimal(price),
    unitCap: unitCap != null ? new Decimal(unitCap) : null,
    unitName: unitName || null,
    discount: discount || null,
    priceIncrease: priceIncrease || PriceIncreaseModel.makeNone()
  };
}
export function makeRangeCost(maxPrice) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    minPrice,
    discount,
    priceIncrease
  } = options;
  return {
    pricingType: ServiceCostType.Range,
    maxPrice: new Decimal(maxPrice),
    minPrice: minPrice != null ? new Decimal(minPrice) : null,
    discount: discount || null,
    priceIncrease: priceIncrease || PriceIncreaseModel.makeNone()
  };
}