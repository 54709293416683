import { BillingTriggerType } from "../../types/api.model";
import { RecurrencePeriodTypes } from "../service-billing/model";
import * as serviceCostModel from "../service-cost";
import { makeServiceTemplate, makeBillingOnApproval, makeBillingOneTimeManual, makeBillingOneTimeDelivery, makeBillingOngoing, makeBillingRepeatableManual, generateId } from "./makers";
import { defaultBillingDayOfWeek } from "../../constants";
import { BillingDayOfMonthModel } from "../BillingDayOfMonth";
import { ServiceIntegrationsModel } from "../ServiceIntegrations";
import { serviceBillingModel } from '..';
export function billingFromDTO(billedUpon) {
  switch (billedUpon.type) {
    case BillingTriggerType.OnApproval:
      return makeBillingOnApproval();
    case BillingTriggerType.OneTimeManual:
      return makeBillingOneTimeManual();
    case BillingTriggerType.OneTimeDelivery:
      return makeBillingOneTimeDelivery();
    case BillingTriggerType.Ongoing:
      return makeBillingOngoing(billedUpon.ongoing.recurrence.period.type);
    case BillingTriggerType.RepeatableManual:
      return makeBillingRepeatableManual(billedUpon.repeatableManual.recurrence.period.type);
  }
}
export function billingToDTO(billing) {
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      return {
        type: BillingTriggerType.OnApproval
      };
    case BillingTriggerType.OneTimeManual:
      return {
        type: BillingTriggerType.OneTimeManual
      };
    case BillingTriggerType.OneTimeDelivery:
      return {
        type: BillingTriggerType.OneTimeDelivery
      };
    case BillingTriggerType.Ongoing:
      return {
        type: BillingTriggerType.Ongoing,
        ongoing: {
          isPaused: false,
          prorateFirstInvoice: false,
          billUpfront: false,
          maxCharges: {
            enabled: false,
            limit: null
          },
          recurrence: {
            period: {
              type: billing.recurrencePeriod,
              monthly: billing.recurrencePeriod === RecurrencePeriodTypes.Monthly ? {
                dayOfMonth: BillingDayOfMonthModel.make().toDBType()
              } : null,
              weekly: billing.recurrencePeriod === RecurrencePeriodTypes.Weekly ? {
                day: defaultBillingDayOfWeek
              } : null,
              biweekly: billing.recurrencePeriod === RecurrencePeriodTypes.BiWeekly ? {
                day: defaultBillingDayOfWeek
              } : null
            }
          }
        }
      };
    case BillingTriggerType.RepeatableManual:
      return {
        type: BillingTriggerType.RepeatableManual,
        repeatableManual: {
          billUpfront: false,
          maxCharges: {
            enabled: false,
            limit: null
          },
          recurrence: {
            period: {
              type: billing.recurrencePeriod,
              monthly: billing.recurrencePeriod === RecurrencePeriodTypes.Monthly ? {
                dayOfMonth: BillingDayOfMonthModel.make().toDBType()
              } : null,
              weekly: billing.recurrencePeriod === RecurrencePeriodTypes.Weekly ? {
                day: defaultBillingDayOfWeek
              } : null,
              biweekly: billing.recurrencePeriod === RecurrencePeriodTypes.BiWeekly ? {
                day: defaultBillingDayOfWeek
              } : null
            }
          }
        }
      };
  }
}
export function fromService(service) {
  const {
    billing
  } = service;
  return makeServiceTemplate({
    id: service.serviceTemplateId,
    name: service.name,
    description: service.description,
    integratedId: service.integratedId,
    cost: service.cost,
    integrations: service.integrations,
    billing: serviceBillingModel.isOnApproval(billing) ? makeBillingOnApproval() : serviceBillingModel.isOneTimeManual(billing) ? makeBillingOneTimeManual() : serviceBillingModel.isOneTimeDelivery(billing) ? makeBillingOneTimeDelivery() : serviceBillingModel.isOngoing(billing) ? makeBillingOngoing(billing.recurrencePeriod) : makeBillingRepeatableManual(billing.recurrencePeriod)
  });
}
export function fromDTO(dto) {
  return {
    id: dto.id,
    name: dto.name,
    description: dto.description,
    status: dto.status,
    cost: serviceCostModel.fromDBDTO(dto.cost),
    billing: billingFromDTO(dto.billedUpon),
    integratedId: dto.integratedId || null,
    origin: dto.origin || null,
    integrations: ServiceIntegrationsModel.fromDTO(dto.integrations)
  };
}
export function toDTO(service) {
  const dto = {
    id: service.id || generateId(),
    name: service.name,
    description: service.description,
    status: service.status,
    cost: serviceCostModel.toDBDTO(service.cost),
    billedUpon: billingToDTO(service.billing)
  };
  if (service.integratedId) {
    dto.integratedId = service.integratedId;
  }
  if (service.origin) {
    dto.origin = service.origin;
  }
  if (service.integrations.hasIntegrations()) {
    dto.integrations = service.integrations.toJSON();
  }
  return dto;
}