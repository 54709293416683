import { useMemo } from 'react';
import { useStoreActions, useStoreStatic } from "../store/store-context";
import { selectors } from "../store/state-selectors";
import { useGlobalContext } from 'seagull';
import { ErrorResponse, ServerError } from "../infra/ErrorResponse";

/** Returns functions that make requests to the back-end for service-template CRUD API. */
export const useServiceTemplateApi = () => {
  const {
    showErrorToast
  } = useGlobalContext();
  const {
    getState
  } = useStoreStatic();
  const {
    api,
    updateBusinessClientData
  } = useStoreActions();
  return useMemo(() => {
    const getBusinessId = () => selectors.user.businessId(getState());
    const handleServiceTemplateExistsError = (err, serviceTemplateName) => {
      if (new ErrorResponse(err).hasServerError(ServerError.ServiceTemplateAlreadyExists)) {
        showErrorToast("A service with the name ".concat(serviceTemplateName, " already exists"));
      }
      throw err;
    };
    async function updateModifiedAServiceTemplate(value) {
      const bid = getBusinessId();
      const {
        modifiedAServiceTemplate
      } = selectors.user.businessClientData(getState(), bid) || {};
      if (bid && !modifiedAServiceTemplate) {
        await updateBusinessClientData(bid, {
          modifiedAServiceTemplate: true
        });
      }
      return value;
    }
    return {
      get() {
        let {
          includeDeleted = false,
          includeAdhocCharges = false
        } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        return api.serviceTemplate.getAll(getBusinessId(), {
          includeDeleted,
          includeAdhocCharges
        });
      },
      create(serviceTemplate) {
        return api.serviceTemplate.create(serviceTemplate, getBusinessId()).catch(err => handleServiceTemplateExistsError(err, serviceTemplate.name)).then(updateModifiedAServiceTemplate);
      },
      update(serviceTemplate) {
        return api.serviceTemplate.update(serviceTemplate, getBusinessId()).catch(err => handleServiceTemplateExistsError(err, serviceTemplate.name)).then(updateModifiedAServiceTemplate);
      },
      delete(id) {
        return api.serviceTemplate.delete(id, getBusinessId());
      }
    };
  }, []);
};