// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nyedx{margin-bottom:16px}.GPekq{display:flex;gap:8px}.C7RZV{display:flex;flex-wrap:wrap;align-items:center;gap:34px 16px}.LB950{width:fit-content}.D2M32{width:600px}.zwdUi{display:flex;flex-direction:row;gap:8px;width:fit-content}.zwdUi button{width:312px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionTitle": `Nyedx`,
	"actionButtonsContainer": `GPekq`,
	"savedPaymentMethods": `C7RZV`,
	"accountantAccessCalloutGridItem": `LB950`,
	"accountantAccessCallout": `D2M32`,
	"accountantAccessCalloutContent": `zwdUi`
};
export default ___CSS_LOADER_EXPORT___;
