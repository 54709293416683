import { BillingTriggerType } from "../../types/api.model";
import { makeMaxCharges, makeOnApprovalBilling, makeOneTimeDeliveryBilling, makeOneTimeManualBilling, makeOngoingBilling, makeRepeatableManualBilling } from "./makers";
import { RecurrencePeriodTypes } from "./model";
import { BillingDayOfMonthModel } from "../BillingDayOfMonth";
import { defaultBillingDayOfWeek } from "../../constants";
import * as selectors from "./selectors";
export function fromDTO(dto, isAwaitingApproval) {
  const {
    trigger,
    recurrencePeriod,
    billingDayOfMonth,
    billingDayOfWeek,
    isBilledUpfront,
    billUpfrontState,
    isPaused,
    isProrated,
    maxCharges
  } = dto;
  switch (trigger) {
    case 'onApproval':
    case BillingTriggerType.OnApproval:
      return makeOnApprovalBilling();
    case BillingTriggerType.OneTimeManual:
      return makeOneTimeManualBilling();
    case BillingTriggerType.OneTimeDelivery:
      return makeOneTimeDeliveryBilling();
    case BillingTriggerType.RepeatableManual:
      if (recurrencePeriod !== '' && recurrencePeriod != null) return makeRepeatableManualBilling(recurrencePeriod, {
        billingDayOfMonth: billingDayOfMonth && BillingDayOfMonthModel.make(billingDayOfMonth),
        billingDayOfWeek,
        isBilledUpfront,
        maxCharges
      });else break;
    case BillingTriggerType.Ongoing:
      if (recurrencePeriod !== '' && recurrencePeriod != null) return makeOngoingBilling(recurrencePeriod, {
        billingDayOfMonth: billingDayOfMonth && BillingDayOfMonthModel.make(billingDayOfMonth),
        billingDayOfWeek,
        isBilledUpfront,
        billUpfrontState: billUpfrontState != null ? billUpfrontState : undefined,
        isProrated,
        isPaused,
        maxCharges,
        isAwaitingApproval
      });else break;
  }
}
export function toDTO(billing) {
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      return {
        trigger: BillingTriggerType.OnApproval,
        billingDayOfMonth: null,
        billingDayOfWeek: null,
        isBilledUpfront: false,
        isPaused: false,
        isProrated: false,
        maxCharges: makeMaxCharges(false),
        recurrencePeriod: ''
      };
    case BillingTriggerType.OneTimeManual:
      return {
        trigger: BillingTriggerType.OneTimeManual,
        billingDayOfMonth: null,
        billingDayOfWeek: null,
        isBilledUpfront: false,
        isPaused: false,
        isProrated: false,
        maxCharges: makeMaxCharges(false),
        recurrencePeriod: ''
      };
    case BillingTriggerType.OneTimeDelivery:
      return {
        trigger: BillingTriggerType.OneTimeDelivery,
        billingDayOfMonth: null,
        billingDayOfWeek: null,
        isBilledUpfront: false,
        isPaused: false,
        isProrated: false,
        maxCharges: makeMaxCharges(false),
        recurrencePeriod: ''
      };
    case BillingTriggerType.Ongoing:
      return {
        trigger: BillingTriggerType.Ongoing,
        isBilledUpfront: billing.isBilledUpfront,
        billUpfrontState: billing.isBilledUpfront ? billing.billUpfrontState : null,
        recurrencePeriod: billing.recurrencePeriod,
        billingDayOfMonth: billing.billingDayOfMonth ? billing.billingDayOfMonth.toJSON() : null,
        billingDayOfWeek: billing.billingDayOfWeek,
        isProrated: billing.isProrated,
        isPaused: billing.isPaused,
        maxCharges: billing.maxCharges
      };
    case BillingTriggerType.RepeatableManual:
      return {
        trigger: BillingTriggerType.RepeatableManual,
        billingDayOfMonth: billing.billingDayOfMonth ? billing.billingDayOfMonth.toJSON() : null,
        billingDayOfWeek: billing.billingDayOfWeek,
        isBilledUpfront: billing.isBilledUpfront,
        recurrencePeriod: billing.recurrencePeriod,
        maxCharges: billing.maxCharges,
        isPaused: false,
        isProrated: false
      };
  }
}
export function toDBServiceBillingTrigger(billing) {
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      return {
        type: BillingTriggerType.OnApproval
      };
    case BillingTriggerType.OneTimeManual:
      return {
        type: BillingTriggerType.OneTimeManual
      };
    case BillingTriggerType.OneTimeDelivery:
      return {
        type: BillingTriggerType.OneTimeDelivery
      };
    case BillingTriggerType.Ongoing:
      return {
        type: BillingTriggerType.Ongoing,
        ongoing: {
          billUpfront: billing.isBilledUpfront,
          billUpfrontState: billing.billUpfrontState,
          isPaused: billing.isPaused,
          recurrence: toDBRecurrenceData(billing),
          prorateFirstInvoice: billing.isProrated,
          maxCharges: {
            enabled: selectors.maxChargesEnabled(billing),
            limit: selectors.maxChargesLimit(billing)
          }
        }
      };
    case BillingTriggerType.RepeatableManual:
      return {
        type: BillingTriggerType.RepeatableManual,
        repeatableManual: {
          billUpfront: billing.isBilledUpfront,
          recurrence: toDBRecurrenceData(billing),
          maxCharges: {
            enabled: selectors.maxChargesEnabled(billing),
            limit: selectors.maxChargesLimit(billing)
          }
        }
      };
  }
}
function toDBRecurrenceData(billing) {
  const {
    recurrencePeriod
  } = billing;
  const billingDayOfMonth = billing.billingDayOfMonth || BillingDayOfMonthModel.make();
  const billingDayOfWeek = billing.billingDayOfWeek || defaultBillingDayOfWeek;
  return {
    period: {
      type: recurrencePeriod,
      monthly: recurrencePeriod === RecurrencePeriodTypes.Monthly ? {
        dayOfMonth: billingDayOfMonth.toDBType()
      } : null,
      weekly: recurrencePeriod === RecurrencePeriodTypes.Weekly ? {
        day: billingDayOfWeek
      } : null,
      biweekly: recurrencePeriod === RecurrencePeriodTypes.BiWeekly ? {
        day: billingDayOfWeek
      } : null
    }
  };
}