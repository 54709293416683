import { RelationshipStatus } from "../relationship/model";
import { fullName } from "../../infra/utils";
export const id = rel => rel.id;
export const customerBusinessId = rel => rel.customer.id;
export const customerBusinessName = rel => rel.customer.name;
export const progressStatus = rel => rel.progressStatus;
export const fundingSource = rel => rel.fundingSource;
export const canCompleteVerification = rel => !!rel.fundingSource && rel.fundingSource.canCompleteVerification();
export const opposingPartyName = (rel, isVendor) => isVendor ? rel.customer.name : rel.vendor.name;
export function customerName(rel) {
  return rel.suggestedCustomerName || rel.customer.name;
}
export const isApproved = rel => rel.status === RelationshipStatus.Approved;
export const isTerminated = rel => rel.status === RelationshipStatus.Terminated;
export const isSample = rel => rel.isSample;
export function sellerFullName(rel) {
  return fullName(rel.seller.firstName, rel.seller.lastName);
}
export function sellerId(rel) {
  return rel.seller.id;
}
export function agreementStatus(rel) {
  return rel.agreementStatusV2;
}