function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
import { legalTermsModel, relationshipModel, serviceModel } from "../../models";
export function mapAgreementToDraft(agreement, mappingOptions) {
  var _agreement$signatorie;
  const isWithdrawToEdit = mappingOptions.strategy === 'withdraw-to-edit';
  const isDuplicateProposal = mappingOptions.strategy === 'duplicate-proposal';
  const isDuplicateAgreement = mappingOptions.strategy === 'duplicate-agreement';
  const shouldDuplicateServiceOrBundles = relationshipModel.isPending(agreement) && agreement.serviceBundles && agreement.serviceBundles.bundles.length > 0 ? 'bundles' : 'services';
  const translateService = s => serviceModel.toDTO(serviceModel.setAwaitingApproval(s));
  return {
    id: generateUniqueId('draft'),
    netTerms: agreement.netTerms,
    coverLetter: agreement.coverLetter,
    extraClause: agreement.extraClause || '',
    requirePaymentMethod: agreement.requirePaymentMethod || false,
    allowManualPayments: agreement.allowManualPayments,
    isExistingCustomer: false,
    lastModifiedOn: Date.now(),
    lastModifiedBy: null,
    legalTerms: legalTermsModel.toDraftLegalTerms(agreement.legalTerms, !mappingOptions.legalTermsDocIds.includes(agreement.legalTerms.id)),
    services: shouldDuplicateServiceOrBundles === 'services' ? agreement.services.map(translateService) : [],
    serviceBundles: agreement.serviceBundles != null && shouldDuplicateServiceOrBundles === 'bundles' ? {
      bundles: agreement.serviceBundles.bundles.map(bundle => _objectSpread(_objectSpread({}, bundle), {}, {
        services: bundle.services.map(translateService)
      }))
    } : undefined,
    customNotificationText: agreement.customNotificationText,
    title: agreement.title,
    effectiveDate: isWithdrawToEdit ? agreement.effectiveDate : null,
    feeControls: agreement.feeControls,
    creationStrategy: isWithdrawToEdit ? 'withdraw-to-edit' : null,
    clientContactId: isDuplicateAgreement ? null : agreement.clientContactId,
    isSample: agreement.isSample,
    amendmentsApprovalMethod: agreement.amendmentsApprovalMethod,
    invoicesApprovalMethod: agreement.invoicesApprovalMethod,
    requestAccountantAccess: agreement.requestAccountantAccess,
    reviewers: isWithdrawToEdit || isDuplicateProposal ? [...(((_agreement$signatorie = agreement.signatories) === null || _agreement$signatorie === void 0 ? void 0 : _agreement$signatorie.map(s => {
      return {
        firstName: s.firstName || undefined,
        lastName: s.lastName || undefined,
        email: s.email,
        isSignatory: true
      };
    })) || []), ...agreement.reviewers.map(r => ({
      firstName: r.firstName || undefined,
      lastName: r.lastName || undefined,
      email: r.email,
      isSignatory: false
    }))] : [],
    introVideo: agreement.introVideo
  };
}