import { ServiceCostType } from "./model";
import { utils } from "../../utils";
import { RoundPrices } from "../PriceIncreaseModel";
export function isFixedPrice(cost) {
  return cost.pricingType === ServiceCostType.Fixed;
}
export function isVariablePrice(cost) {
  return cost.pricingType === ServiceCostType.Variable;
}
export function isRangePrice(cost) {
  return cost.pricingType === ServiceCostType.Range;
}
export function price(cost) {
  if (cost.pricingType === ServiceCostType.Fixed) {
    return cost.price;
  } else if (cost.pricingType === ServiceCostType.Variable) {
    return cost.price;
  } else {
    return cost.maxPrice;
  }
}
export function minPrice(cost) {
  return cost.pricingType === ServiceCostType.Range ? cost.minPrice : null;
}
export function finalPrice(cost, quantity) {
  if (isRangePrice(cost)) {
    return utils.applyDiscount(cost.maxPrice, cost.discount);
  } else if (isFixedPrice(cost)) {
    return utils.applyDiscount(cost.price, cost.discount);
  } else {
    return utils.calculateFinalPrice(cost.price, cost.discount, quantity);
  }
}
export function applyDiscount(cost) {
  if (isFixedPrice(cost) || isVariablePrice(cost)) {
    return utils.applyDiscount(cost.price, cost.discount);
  } else {
    return utils.applyDiscount(cost.maxPrice, cost.discount);
  }
}
export function minPriceFinal(cost) {
  return cost.pricingType === ServiceCostType.Range && cost.minPrice ? utils.applyDiscount(cost.minPrice, cost.discount) : null;
}
export function discount(cost) {
  return cost.discount;
}
export function unitCap(cost) {
  return isVariablePrice(cost) ? cost.unitCap : null;
}
export function unitName(cost) {
  if (cost.pricingType === ServiceCostType.Fixed || cost.pricingType === ServiceCostType.Range) {
    return null;
  } else {
    return cost.unitName;
  }
}
export function priceIncrease(cost) {
  return cost.priceIncrease;
}
export function nextYearPrice(cost) {
  if (cost.priceIncrease.isNonPositiveIncrease()) {
    return null;
  }
  const rawResult = finalPrice(cost, 1).times(cost.priceIncrease.percentIncrease().plus(100)).div(100);
  switch (cost.priceIncrease.roundPrices()) {
    case RoundPrices.None:
      return rawResult.toDP(2).toNumber();
    case RoundPrices.Down1:
      return rawResult.floor().toNumber();
    case RoundPrices.Down5:
      return rawResult.minus(rawResult.mod(5)).toNumber();
    case RoundPrices.Down10:
      {
        return rawResult.minus(rawResult.mod(10)).toNumber();
      }
  }
}