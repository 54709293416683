// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Als6o{display:flex;gap:8px;align-items:center}.vULJB{display:flex;gap:unset;align-items:baseline}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price": `Als6o`,
	"priceAndUnit": `vULJB`
};
export default ___CSS_LOADER_EXPORT___;
