import { serviceTemplateModel } from "../../models";
import { utils } from "../../utils";
export const baseColumnDefs = [{
  width: [200, 400],
  sortable: true
},
// service name
{
  width: 130,
  sortable: true
},
// price
{
  width: 120,
  sortable: true
},
// frequency
{
  width: 110,
  sortable: true
} // billing
];
export const baseHeaderItems = [{
  value: 'Service name'
}, {
  value: 'Rate'
}, {
  value: 'Billing occurrence'
}, {
  value: 'Billing trigger'
}];
export const defaultSort = {
  colIndex: 0,
  sort: 'desc'
};
export const sortIds = baseHeaderItems.map(item => item.value);
export async function saveServiceTemplate(_ref) {
  let {
    listener,
    modifiedService,
    originalService,
    repo,
    storePopulator
  } = _ref;
  listener.saveStarted();
  try {
    if (originalService) {
      await repo.update(modifiedService);
    } else {
      await repo.create(modifiedService);
    }
    await storePopulator.populateServiceTemplates();
  } catch (err) {
    listener.saveFailed();
    throw err;
  } finally {
    listener.saveFinished();
  }
}
function sortByName(a, b) {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
function sortByPrice(a, b) {
  const [aPrice, bPrice] = [a, b].map(service => utils.applyDiscount(serviceTemplateModel.isFixedPrice(service.cost) ? service.cost.price : serviceTemplateModel.isVariablePrice(service.cost) ? service.cost.price : service.cost.minPrice != null ? service.cost.minPrice : service.cost.maxPrice, service.cost.discount));
  return aPrice.minus(bPrice).toNumber();
}
function sortByFrequency(a, b) {
  const [aFrequency, bFrequency] = [a, b].map(service => serviceTemplateModel.isRecurring(service.billing) ? service.billing.recurrencePeriod : 'one');
  return aFrequency < bFrequency ? -1 : aFrequency > bFrequency ? 1 : 0;
}
function sortByBilling(a, b) {
  const [aBilling, bBilling] = [a, b].map(service => serviceTemplateModel.isManual(service.billing) ? 'manual' : 'automatic');
  return aBilling < bBilling ? -1 : aBilling > bBilling ? 1 : 0;
}
function sortByQboStatus(a, b, qboItemsById) {
  var _qboItemsById$a$integ, _qboItemsById$b$integ;
  if (!a.integratedId) return b.integratedId ? 1 : 0;
  if (!b.integratedId) return -1;
  const aName = (_qboItemsById$a$integ = qboItemsById[a.integratedId]) === null || _qboItemsById$a$integ === void 0 ? void 0 : _qboItemsById$a$integ.name.toLowerCase();
  const bName = (_qboItemsById$b$integ = qboItemsById[b.integratedId]) === null || _qboItemsById$b$integ === void 0 ? void 0 : _qboItemsById$b$integ.name.toLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
export const sortFunctions = [isAsc => (a, b) => (isAsc ? -1 : 1) * sortByName(a, b), isAsc => (a, b) => (isAsc ? -1 : 1) * sortByPrice(a, b) || sortByName(a, b), isAsc => (a, b) => (isAsc ? -1 : 1) * sortByFrequency(a, b) || sortByName(a, b), isAsc => (a, b) => (isAsc ? -1 : 1) * sortByBilling(a, b) || sortByName(a, b), (isAsc, qboItemsById) => (a, b) => (isAsc ? -1 : 1) * sortByQboStatus(a, b, qboItemsById) || sortByName(a, b)];