// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H8T9G{margin-bottom:20px}.XKwjm{display:flex;align-items:center;justify-content:center;height:200px}.SrZ_G{text-align:center;margin-top:16px;white-space:pre-wrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"callout": `H8T9G`,
	"loader": `XKwjm`,
	"empty": `SrZ_G`
};
export default ___CSS_LOADER_EXPORT___;
