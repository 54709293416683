const _excluded = ["session", "authToken", "authFlowDone", "claims", "userInfo", "userClientData", "ff"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import produce from 'immer';
import lodashGet from 'lodash/get';
import { extractClaimsFromToken, handlePromise, handleRequest, wait } from "../infra/utils";
import { IntegrationSource } from "../types/api.model";
import { defaultFeatureFlagsKey, getDefaultInitialState, getOrCreateStoreLocation, initMakeFetchAndStoreFunction } from "./store.utils";
import { selectors } from "./state-selectors";
import { defaultAgreementStatusFilter } from "../pages/AgreementsDashboard/agreementsDashboardFilters";
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import { RevenueReportAggregateEnum } from 'anchor-scout/types';
import { HistoricRevenueModel } from "../models/HistoricRevenueModel";
export function initStoreActions(_ref) {
  let {
    api,
    getState,
    setState,
    getIntercom,
    onReportToSentry,
    onLogBI,
    getNow
  } = _ref;
  const makeFetchAndStoreFunction = initMakeFetchAndStoreFunction({
    setState,
    getState,
    getNow
  });

  /** Resets the store state */
  function reset(authFlowDone) {
    setState(getDefaultInitialState(_objectSpread({}, getState().session), {
      authFlowDone,
      featureFlags: getState().ff.featureFlags
    }), false, 'reset');
  }

  /** Resets the business data. used when switching businesses  */
  function resetBusinessData() {
    const _getDefaultInitialSta = getDefaultInitialState(getState().session),
      {
        session: _1,
        authToken: _2,
        authFlowDone: _3,
        claims: _4,
        userInfo: _5,
        userClientData: _6,
        ff: _7
      } = _getDefaultInitialSta,
      resetData = _objectWithoutProperties(_getDefaultInitialSta, _excluded);
    setState(resetData, false, 'resetBusinessData');
  }

  /**
   * Starts a new request. baseLocation is the key in the store or an array that
   * represents a path in the store.
   */
  function requestStart(baseLocation) {
    setState(produce(state => {
      const loc = getOrCreateStoreLocation(state, baseLocation);
      loc.loading = true;
    }), false, "requestStart ".concat(JSON.stringify(baseLocation)));
  }

  /**
   * Ends a request successfully with response data. baseLocation is the key in the
   * store or an array that represents a path in the store.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function requestSuccess(baseLocation, data) {
    setState(produce(state => {
      const loc = getOrCreateStoreLocation(state, baseLocation);
      loc.loading = false;
      loc.lastSuccessfulFetch = Date.now();
      loc.data = data;
      loc.error = null;
    }), false, "requestSuccess ".concat(JSON.stringify(baseLocation)));
  }

  /**
   * Ends a request with an error. baseLocation is the key in the store or an array that
   * represents a path in the store.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function requestFail(baseLocation, error) {
    setState(produce(state => {
      const loc = getOrCreateStoreLocation(state, baseLocation);
      loc.loading = false;
      loc.data = null;
      loc.error = error;
    }), false, "requestFail ".concat(JSON.stringify(baseLocation)));
  }
  function setRelationshipScope(relationshipScope) {
    if (isEqual(relationshipScope, getState().relationshipScope)) {
      return;
    }
    if (!selectors.impersonatingAs(getState())) {
      getIntercom()('boot', {
        relationship_id: relationshipScope && relationshipScope.id
      });
    }
    setState({
      relationshipScope
    }, false, 'relationshipScope');
  }
  function setRelationshipVendorId(id) {
    setState({
      ff: _objectSpread(_objectSpread({}, getState().ff), {}, {
        relationshipVendorId: id
      })
    }, false, 'setRelationshipVendorId');
  }
  /**
   * Fetches the feature-flags and saves in the store. If given a businessId will fetch
   * the feature-flags for that businessId. Otherwise, will fetch global feature-flags.
   */
  async function fetchFeatureFlags(businessId, authToken) {
    const storeLocation = ['ff', 'featureFlags', businessId || defaultFeatureFlagsKey];
    const featureFlags = lodashGet(getState(), storeLocation);
    if (featureFlags && (featureFlags.loading || featureFlags.data)) {
      // No need to load twice if data exists or a request is already in progress.
      return;
    }
    requestStart(storeLocation);
    try {
      const featureFlags = await api.featureFlag.getAll(businessId, authToken);
      requestSuccess(storeLocation, featureFlags);
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function resetStoreAndFinishAuthFlow() {
    reset();
    await fetchFeatureFlags();
    setState({
      authFlowDone: true
    });
  }
  async function setAuthToken(authToken) {
    const claims = extractClaimsFromToken(authToken);
    if (!claims) {
      return await resetStoreAndFinishAuthFlow();
    }
    if (selectors.isResetPasswordFlow({
      claims
    })) {
      await fetchFeatureFlags(selectors.user.businessId(getState()), authToken);
      setState({
        authToken,
        claims,
        authFlowDone: true
      });
      return;
    }
    setState({
      claims,
      authToken
    });
    const userInfoResponse = await handlePromise(api.auth.getUserInfo());
    if (!userInfoResponse.success) {
      await resetStoreAndFinishAuthFlow();
      throw new Error('Failed to get user info');
    }
    const userInfo = userInfoResponse.data;
    if (userInfo == null) {
      return await resetStoreAndFinishAuthFlow();
    }
    const isVendor = userInfo.isVendor();
    const businessIdFromUserInfo = userInfo.businessId();
    const businessIdFromClaims = (claims === null || claims === void 0 ? void 0 : claims.businessId) || undefined; // No business in claims can be an empty string, and we want it undefined to compare to userInfo.

    if (businessIdFromUserInfo !== businessIdFromClaims && !businessIdFromClaims) {
      onReportToSentry("authToken/me-query businessId mismatch. token:".concat(businessIdFromClaims, ", meQuery:").concat(businessIdFromUserInfo));
    }

    // We need to fetchIntegrations before setting userInfo to prevent a race condition where pages
    // are loaded before integrations are available when logging in to a concrete page that needs QBO data.
    await Promise.all(compact([isVendor && handlePromise(api.auth.getUserVideos().then(setUserVideos)), isVendor && businessIdFromUserInfo && handlePromise(api.business.getInfo(businessIdFromUserInfo).then(setBusinessInfo)), isVendor && handlePromise(fetchIntegrations()), handlePromise(fetchUserClientData(userInfo)), businessIdFromUserInfo && handlePromise(fetchBusinessClientData(businessIdFromUserInfo)), businessIdFromUserInfo && fetchFeatureFlags(businessIdFromUserInfo)]));
    setUserInfo(userInfo);

    // Necessary for the store to update before the next render cycle, so useFeatureFlags
    // will get the ones from the user's business instead of the default ones.
    await wait(0);
    setState({
      authFlowDone: true
    });
  }
  async function fetchUserInfo() {
    const userInfo = await api.auth.getUserInfo();
    setUserInfo(userInfo);
    return userInfo;
  }
  function setUserInfo(userInfo) {
    setState({
      userInfo
    }, false, 'setUserInfo');
  }
  const setUserVideos = videos => {
    setState({
      videos
    }, false, 'videos');
  };
  const setBusinessInfo = businessInfo => setState({
    businessInfo
  });
  const markHasSentAProposal = () => {
    const {
      businessInfo
    } = getState();
    if (!businessInfo || businessInfo.hasSentAProposal) return;
    setState({
      businessInfo: _objectSpread(_objectSpread({}, businessInfo), {}, {
        hasSentAProposal: true
      })
    });
  };
  function setWorksAt(worksAt) {
    setState(state => ({
      userInfo: state.userInfo ? state.userInfo.setWorksAt(worksAt) : null
    }), false, 'setWorksAt');
  }
  function setUserClientData(userClientData) {
    setState({
      userClientData
    }, false, 'setUserClientData');
  }
  function setBusinessClientData(businessId, data) {
    setState({
      businessClientData: _objectSpread(_objectSpread({}, getState().businessClientData), {}, {
        [businessId]: data
      })
    }, false, 'setBusinessClientData');
  }
  function setStripeLoadingFailed(stripeLoadingFailed) {
    setState({
      stripeLoadingFailed
    }, false, 'setStripeLoadingFailed');
  }
  function setInAppNotificationsDrawerOpen(inAppNotificationsDrawerOpen) {
    setState({
      inAppNotificationsDrawerOpen
    }, false, 'inAppNotificationsDrawerOpen');
    if (inAppNotificationsDrawerOpen) {
      setState({
        showNotificationPimple: false
      }, false, 'newNotificationsCounter');
    }
  }
  function setQboIntegration(qbo) {
    const integrations = getState().integrations.data || [];
    const newIntegrations = integrations.filter(i => i.source !== IntegrationSource.Qbo).concat([qbo]);
    setState({
      integrations: _objectSpread(_objectSpread({}, getState().integrations), {}, {
        data: newIntegrations
      })
    });
  }
  async function updateBusinessColor(color) {
    const worksAt = selectors.user.business(getState());
    if (worksAt) {
      await api.vendor.updateBusinessDetails(worksAt.id, {
        color
      });
      setWorksAt(Object.assign({}, worksAt, {
        branding: {
          color
        }
      }));
    }
  }
  async function fetchInvoiceSummaries() {
    requestStart('invoices');
    const invoices = await handlePromise(api.invoice.getSummariesScout());
    if (invoices.success) {
      requestSuccess('invoices', invoices.data);
    } else {
      requestFail('invoices', invoices.error);
    }
  }
  async function fetchPayouts() {
    requestStart('payouts');
    const payoutsData = await handleRequest(api.payout.getAll());
    if (!payoutsData.success) {
      requestFail('payouts', payoutsData.error);
      throw payoutsData.error;
    } else {
      requestSuccess('payouts', payoutsData.data);
      return payoutsData.data;
    }
  }
  async function fetchAgreements() {
    const businessId = selectors.user.businessId(getState());
    const isVendor = selectors.user.isVendor(getState());
    const storeLocation = ['agreements'];
    if (!businessId) {
      return [];
    }
    requestStart(storeLocation);
    try {
      const agreements = await api.relationship.getSummaries({
        businessId,
        type: 'agreement',
        isVendor,
        filters: {
          agreementStatus: defaultAgreementStatusFilter
        }
      });
      requestSuccess(storeLocation, agreements);
      return agreements;
    } catch (err) {
      requestFail(storeLocation, err);
      return [];
    }
  }
  async function fetchContacts() {
    const storeLocation = 'contacts';
    requestStart(storeLocation);
    try {
      const contacts = await api.contact.getAll();
      requestSuccess(storeLocation, contacts);
      return contacts;
    } catch (err) {
      requestFail(storeLocation, err);
      return null;
    }
  }
  async function fetchDrafts() {
    const storeLocation = 'drafts';
    requestStart(storeLocation);
    try {
      const drafts = await api.draft.getSummaries();
      requestSuccess(storeLocation, drafts);
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchQboCustomers() {
    const storeLocation = 'qboCustomers';
    requestStart(storeLocation);
    try {
      const qboCustomers = await api.qbo.getCustomers();
      requestSuccess(storeLocation, qboCustomers);
      return qboCustomers;
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchQboItems() {
    const storeLocation = 'qboItems';
    requestStart(storeLocation);
    try {
      const response = await api.qbo.getItems();
      requestSuccess(storeLocation, response);
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchFinancialCentsItems() {
    const storeLocation = ['financialCents', 'items'];
    requestStart(storeLocation);
    try {
      const response = await api.financialCents.getItems();
      requestSuccess(storeLocation, response);
      return response;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  async function fetchKarbonItems() {
    const storeLocation = ['karbon', 'items'];
    requestStart(storeLocation);
    try {
      const response = await api.karbon.getItems();
      requestSuccess(storeLocation, response);
      return response;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  async function fetchClientHubItems() {
    const storeLocation = ['clientHub', 'items'];
    requestStart(storeLocation);
    try {
      const response = await api.clientHub.getItems();
      requestSuccess(storeLocation, response);
      return response;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  async function fetchFinancialCentsUsers() {
    const storeLocation = ['financialCents', 'users'];
    requestStart(storeLocation);
    try {
      const response = await api.financialCents.getUsers();
      requestSuccess(storeLocation, response);
      return response;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  async function fetchKarbonUsers() {
    const storeLocation = ['karbon', 'users'];
    requestStart(storeLocation);
    try {
      const response = await api.karbon.getUsers();
      requestSuccess(storeLocation, response);
      return response;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  async function fetchQboClasses() {
    const allowToCustomizeQboClasses = selectors.integration.allowToCustomizeQboClasses(getState());
    const storeLocation = 'qboClasses';
    if (!allowToCustomizeQboClasses) {
      requestSuccess(storeLocation, []);
      return;
    }
    try {
      requestStart(storeLocation);
      const response = await api.qbo.getClasses();
      requestSuccess(storeLocation, response);
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchProposals() {
    const businessId = selectors.user.businessId(getState());
    const isVendor = selectors.user.isVendor(getState());
    const storeLocation = 'proposals';
    if (!businessId) {
      return;
    }
    requestStart(storeLocation);
    try {
      const proposals = await api.relationship.getSummaries({
        businessId,
        type: 'proposal',
        isVendor
      });
      requestSuccess(storeLocation, proposals);
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchProposalTemplates() {
    const storeLocation = 'proposalTemplates';
    requestStart(storeLocation);
    try {
      const proposalTemplates = await api.proposalTemplates.getAll();
      requestSuccess(storeLocation, proposalTemplates);
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchMonthlyEarnings() {
    const storeLocation = 'monthlyEarnings';
    requestStart(storeLocation);
    try {
      const monthlyEarnings = await api.report.getMonthlyEarnings();
      requestSuccess(storeLocation, monthlyEarnings);
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchHistoricAgreementStats() {
    const businessId = selectors.user.businessId(getState());
    if (!businessId) return;
    const storeLocation = 'historicAgreementStats';
    requestStart(storeLocation);
    try {
      const historicAgreementStats = await api.report.getHistoricAgreementStats(businessId);
      requestSuccess(storeLocation, historicAgreementStats);
      return historicAgreementStats;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  async function fetchSalesSummary() {
    const storeLocation = 'salesSummary';
    requestStart(storeLocation);
    try {
      const salesSummary = await api.report.getSalesSummary();
      requestSuccess(storeLocation, salesSummary);
      return salesSummary;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  const fetchHistoricRevenueStats = async () => {
    const storeLocation = 'historicRevenueStats';
    const businessId = selectors.user.businessId(getState());
    if (!businessId) throw new Error('No businessId');
    requestStart(storeLocation);
    try {
      const [historicPaidInvoices, historicPaidExternallyInvoices] = await Promise.all([api.reports.revenueReport({
        businessId,
        end: undefined,
        start: undefined,
        aggregate: RevenueReportAggregateEnum.Month,
        services: [],
        customers: [],
        breakdowns: []
      }), api.report.getHistoricPaidExternallyInvoices()]);
      const historicRevenueStats = HistoricRevenueModel.fromDTO(historicPaidInvoices, historicPaidExternallyInvoices);
      requestSuccess(storeLocation, historicRevenueStats);
      return historicRevenueStats;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  };
  async function fetchInvoicesDigest() {
    const storeLocation = 'invoicesDigest';
    requestStart(storeLocation);
    try {
      const invoicesDigest = await api.invoice.getDigested();
      requestSuccess(storeLocation, invoicesDigest);
      return invoicesDigest;
    } catch (err) {
      requestFail(storeLocation, err);
      throw err;
    }
  }
  const fetchPayoutsSummary = makeFetchAndStoreFunction({
    fetcher: api.payout.getSummary,
    getLoadableContent: state => state.payoutsSummary
  });
  const dumbFetchPersonaKYBStatus = makeFetchAndStoreFunction({
    fetcher: api.vendor.getKYBStatus,
    getLoadableContent: state => state.personaKYBStatus,
    allowStaleDataFor: 2000
  });
  function fetchPersonaKYBStatus() {
    const worksAt = selectors.user.business(getState());
    return worksAt ? dumbFetchPersonaKYBStatus(worksAt.id) : Promise.resolve(null);
  }
  const fetchIntegrations = makeFetchAndStoreFunction({
    fetcher: api.integration.getAll,
    getLoadableContent: state => state.integrations,
    allowStaleDataFor: 2000
  });
  async function fetchKarbonIntegration() {
    const storeLocation = 'integrations';
    requestStart(storeLocation);
    try {
      const currentIntegrations = getState().integrations;
      if (!currentIntegrations.data) {
        return fetchIntegrations();
      }
      const integration = await api.karbon.getIntegration();
      const newIntegrations = currentIntegrations.data.filter(i => i.source !== 'karbon').concat([integration]);
      requestSuccess(storeLocation, newIntegrations);
      return integration;
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function fetchInAppNotifications() {
    requestStart('inAppNotifications');
    try {
      const {
        pushedNotifications,
        counter
      } = await api.inAppNotifications.getAll();
      requestSuccess('inAppNotifications', pushedNotifications);
      const inAppNotificationsDrawerOpen = getState().inAppNotificationsDrawerOpen;
      const shouldShowPimple = !inAppNotificationsDrawerOpen && Boolean(counter);
      setState({
        showNotificationPimple: shouldShowPimple
      }, false, 'newNotificationsCounter');
    } catch (err) {
      requestFail('inAppNotifications', err);
    }
  }
  async function fetchServiceTemplates() {
    const storeLocation = 'serviceTemplates';
    requestStart(storeLocation);
    try {
      const businessId = selectors.user.businessId(getState());
      const {
        active,
        deleted
      } = await api.serviceTemplate.getAll(businessId, {
        includeDeleted: true
      });
      const ret = {
        active,
        deleted
      };
      requestSuccess(storeLocation, ret);
      return ret;
    } catch (err) {
      requestFail(storeLocation, err);
      return null;
    }
  }
  async function fetchUserClientData(_userInfo) {
    const userInfo = _userInfo !== undefined ? _userInfo : getState().userInfo;
    const userId = userInfo === null || userInfo === void 0 ? void 0 : userInfo.id();
    if (!userId) {
      throw new Error("Can't fetchUserClientData. No userInfo.");
    }
    const data = await api.user.getUserClientData(userId);
    setUserClientData(data);
    return data;
  }
  async function fetchBusinessClientData(businessId) {
    const bid = businessId || selectors.user.businessId(getState());
    if (!bid) {
      return {};
    }
    const data = await api.user.getBusinessClientData(bid);
    setBusinessClientData(bid, data);
    return data;
  }
  const dumbFetchLegalTerms = makeFetchAndStoreFunction({
    fetcher: () => api.business.getLegalTerms(),
    getLoadableContent: state => state.legalTerms
  });
  const fetchLegalTerms = () => dumbFetchLegalTerms().catch(() => null);
  async function updateBusinessClientData(businessId, changes) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const {
      useLocalData
    } = options;
    const currentData = useLocalData ? selectors.user.businessClientData(getState(), businessId) : await fetchBusinessClientData();
    const newData = _objectSpread(_objectSpread({}, currentData), changes);
    await api.user.setBusinessClientData(businessId, newData);
    setBusinessClientData(businessId, newData);
    return newData;
  }
  async function fetchActivityLog(relationshipId) {
    const storeLocation = ['activityLog', relationshipId];
    requestStart(storeLocation);
    try {
      const res = await api.activityLog.get(relationshipId);
      requestSuccess(storeLocation, res);
      return res;
    } catch (err) {
      requestFail(storeLocation, err);
    }
  }
  async function updateUserClientData(changes) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      useLocalData
    } = options;
    const {
      userInfo,
      userClientData
    } = getState();
    const userId = userInfo === null || userInfo === void 0 ? void 0 : userInfo.id();
    if (!userId) {
      throw new Error("Can't updateUserClientData. No userInfo.");
    }
    const currentData = useLocalData ? userClientData : await fetchUserClientData();
    const newData = _objectSpread(_objectSpread({}, currentData), changes);
    await api.user.setUserClientData(userId, newData);
    setUserClientData(newData);
    return newData;
  }

  /** Logs a BI event. */
  function logBI(id, data) {
    const state = getState();
    const {
      relationshipScope
    } = state;
    onLogBI(id, _objectSpread({
      userId: selectors.user.id(state),
      businessId: selectors.user.businessId(state),
      relationshipId: relationshipScope && relationshipScope.id,
      isApprovedRelationship: relationshipScope && relationshipScope.isApproved
    }, data || {}));
  }
  function setBranding(branding) {
    var _getState$userInfo;
    setState({
      userInfo: ((_getState$userInfo = getState().userInfo) === null || _getState$userInfo === void 0 ? void 0 : _getState$userInfo.setBranding(branding)) || null
    }, false, 'setBranding');
  }
  return {
    reset,
    resetBusinessData,
    requestStart,
    requestSuccess,
    requestFail,
    setAuthToken,
    fetchUserInfo,
    setUserVideos,
    setUserInfo,
    setWorksAt,
    setUserClientData,
    setStripeLoadingFailed,
    setInAppNotificationsDrawerOpen,
    setQboIntegration,
    setBusinessInfo,
    markHasSentAProposal,
    updateBusinessColor,
    fetchInvoiceSummaries,
    fetchPayouts,
    fetchAgreements,
    fetchContacts,
    fetchDrafts,
    fetchQboCustomers,
    fetchQboItems,
    fetchQboClasses,
    fetchProposals,
    fetchProposalTemplates,
    fetchMonthlyEarnings,
    fetchHistoricAgreementStats,
    fetchSalesSummary,
    fetchHistoricRevenueStats,
    fetchPayoutsSummary,
    fetchPersonaKYBStatus,
    fetchIntegrations,
    fetchInAppNotifications,
    fetchServiceTemplates,
    fetchUserClientData,
    fetchBusinessClientData,
    fetchActivityLog,
    fetchLegalTerms,
    fetchFinancialCentsItems,
    fetchFinancialCentsUsers,
    fetchKarbonUsers,
    updateBusinessClientData,
    updateUserClientData,
    logBI,
    setRelationshipScope,
    setBranding,
    fetchKarbonItems,
    fetchKarbonIntegration,
    fetchClientHubItems,
    fetchInvoicesDigest,
    ff: {
      setRelationshipVendorId,
      fetchFeatureFlags
    },
    api
  };
}